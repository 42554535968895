import React from 'react';
import PropTypes from 'prop-types';

import Header from '~/components/Header';
import Sidebar from '~/components/Sidebar';
import { WrapperContent, Content } from './styles';

export default function DefaultLayout({ children }) {
  return (
    <>
      <Header />
      <WrapperContent>
        <Sidebar />
        <Content>{children}</Content>
      </WrapperContent>
    </>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
