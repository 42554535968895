import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import { Icon } from 'antd';
import * as Yup from 'yup';

import logo from '~/assets/logo.svg';

import api from '~/services/api';
import history from '~/services/history';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
});

const editSchema = Yup.object().shape({
  password: Yup.string()
    .required('Nova senha é obrigatória')
    .min(6, 'A senha deve possuir 6 caracteres'),
  confirmPassword: Yup.string().when('password', (password, field) =>
    password
      ? field
          .required('Nova senha é obrigatória')
          .oneOf([Yup.ref('password'), null], 'Senhas devem ser iguais')
      : field
  ),
});

export default function SignIn() {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    if (params) {
      setToken(params.get('token'));
    }
  }, []);

  async function handleSubmit(email) {
    setLoading(true);
    try {
      await api.post('reset-password', {
        email,
        redirect_url: window.location.href,
      });
      toast.success('Você receberá um email em instantes');
      history.push('/');
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.error.message);
    }
  }

  async function handleResetPassword(password) {
    setLoading(true);
    try {
      await api.put(`reset-password/${token}`, {
        password,
      });
      toast.success('Senha alterada com sucesso');
      history.push('/');
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.error.message);
      history.push('/');
    }
  }

  return (
    <>
      <Link to="/">
        <img src={logo} alt="logoIPR" />
      </Link>

      {!token && (
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={schema}
          onSubmit={({ email }) => handleSubmit(email)}
        >
          <Form>
            <Field name="email" type="email" placeholder="Seu e-mail" />
            <ErrorMessage component="span" name="email" />

            <button type="submit">
              {loading ? <Icon type="loading" /> : 'Solicitar redefinição'}
            </button>
            <Link to="/">Já tenho conta</Link>
          </Form>
        </Formik>
      )}

      {token && (
        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          validationSchema={editSchema}
          onSubmit={({ password }) => handleResetPassword(password)}
        >
          <Form>
            <Field name="password" type="password" placeholder="Nova senha" />
            <ErrorMessage component="span" name="password" />
            <Field
              name="confirmPassword"
              type="password"
              placeholder="Confirmação nova senha"
            />
            <ErrorMessage component="span" name="confirmPassword" />

            <button type="submit">
              {loading ? <Icon type="loading" /> : 'Salvar nova senha'}
            </button>
          </Form>
        </Formik>
      )}
    </>
  );
}
