import React, { useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';

import { MdContentCut, MdCancel } from 'react-icons/md';

import { SliderFinal } from '../Slider/styles';
import getCroppedImg from './cropImage';
import InputAvatar from '../InputAvatar';

import api from '~/services/api';

import { Container, MainCrop, Control, Button } from './styles';

export default function CropImage({ name, value, onChange }) {
  const [imageSrc, setImageSrc] = useState('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [aspect, setAspect] = useState(1 / 1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (value) {
      setCroppedImage(value.url);
    }
  }, [value]);

  const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  function onCropChange(changedCrop) {
    setCrop(changedCrop);
  }

  function onZoomChange(changedZoom) {
    setZoom(changedZoom);
  }

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  async function showCroppedImage() {
    const finalCroppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);

    const block = finalCroppedImage.split(';');
    // Get the content type of the image
    const contentType = block[0].split(':')[1]; // In this case "image/gif"
    // get the real base64 content of the file
    const realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

    // Convert it to a blob to upload
    const blob = b64toBlob(realData, contentType);

    const data = new FormData();
    data.append('file', blob, fileName);

    const response = await api.post('files', data);

    setCroppedImage(finalCroppedImage);
    setImageSrc('');
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setAspect(4 / 3);
    onChange(name, response.data);
  }

  function readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  async function onFileChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageDataUrl = await readFile(file);

      setFileName(file.name);
      setImageSrc(imageDataUrl);
    }
  }

  function handleCancel() {
    setImageSrc('');
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setAspect(4 / 3);
  }

  return (
    <Container>
      <InputAvatar
        onChange={e => {
          onFileChange(e);
        }}
        croppedImage={croppedImage}
      />
      {imageSrc && (
        <>
          <MainCrop>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={onCropChange}
              onZoomChange={onZoomChange}
              onCropComplete={(croppedArea, croppedAreaPixels) =>
                onCropComplete(croppedArea, croppedAreaPixels)
              }
            />
          </MainCrop>
          <Control>
            <SliderFinal
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              ariaLabel="Zoom"
              onChange={e => setZoom(e)}
            />
            <Button type="button" onClick={() => showCroppedImage()}>
              <MdContentCut />
            </Button>
            <Button
              type="button"
              onClick={() => handleCancel()}
              color="#d71b1b"
            >
              <MdCancel />
            </Button>
          </Control>
        </>
      )}
    </Container>
  );
}
