const Yup = require('yup');

export const storeMemberSchema = Yup.object().shape({
  file_id: Yup.string()
    .nullable()
    .required(),
  name: Yup.string().required('Name é obrigatório'),
  rg: Yup.string().required('Rg é obrigatório'),
  birth: Yup.date()
    .typeError('Data de nascimento é obrigatório')
    .max(new Date(), 'Não pode selecionar datas futuras')
    .required('Data de nascimento é obrigatório'),
  adress: Yup.string().required('Endereço é obrigatório'),
  district: Yup.string().required('Bairro é obrigatório'),
  city: Yup.string().required('Cidade é obrigatório'),
  state: Yup.string().required('Estado é obrigatório'),
  postalCode: Yup.string().required('Cep é obrigatório'),
  birthCity: Yup.string().required('Cidade de nascimento é obrigatório'),
  birthState: Yup.string().required('Estado de nascimento é obrigatório'),
  nameMom: Yup.string().required('Nome da mãe é obrigatório'),
  nameDad: Yup.string().required('Nome do pai é obrigatório'),
  civilStatus: Yup.string().required('Estado civil é obrigatório'),
  gender: Yup.string().required('Selecione o Sexo é obrigatório'),
  status: Yup.string().required('Situação é obrigatória'),
  scholarity: Yup.string().required('Selecione a escolaridade'),
  phone: Yup.string().required('Celular é obrigatório'),
  tel: Yup.string(),
  cpf: Yup.string(),
  email: Yup.string().email('Email inválido'),
  job: Yup.string(),
  spouse: Yup.string(),
  children: Yup.number().default(0),
  reception: Yup.string(),
  dateBaptism: Yup.date().nullable(),
  dateArriveAt: Yup.date().nullable(),
  department: Yup.string().nullable(),
  role: Yup.string().nullable(),
  church: Yup.string().nullable(),
});

export const initialMember = {
  name: '',
  rg: '',
  cpf: '',
  birth: null,
  adress: '',
  district: '',
  city: '',
  state: '',
  postalCode: '',
  tel: '',
  birthCity: '',
  birthState: '',
  nameMom: '',
  nameDad: '',
  civilStatus: '',
  gender: '',
  phone: '',
  email: '',
  scholarity: '',
  job: '',
  spouse: '',
  children: 0,
  status: '',
  reception: '',
  dateBaptism: null,
  dateArriveAt: null,
  department: null,
  role: null,
  church: null,
  file_id: null,
};
