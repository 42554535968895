import styled from 'styled-components';

export const Container = styled.div`
  align-self: center;
`;

export const MainCrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`;

export const Control = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 5px;
  border-radius: 4px;
  border: 1px solid #fff;
  background-color: ${props => (props.color && props.color) || '#449aff'};
  color: #fff;
  font-weight: bold;
  transition: ease 0.2s;
  &:hover {
    opacity: 0.8;
  }
`;
