import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';

import { Container } from './styles';
import TitlePage from '~/components/TitlePage';

import api from '~/services/api';
import history from '~/services/history';

const schema = Yup.object().shape({
  name: Yup.string().required('Título do ministério é obrigatório'),
});

export default function NewDepartment() {
  const [loading, setLoading] = useState(false);

  async function handleSubmit(data) {
    try {
      setLoading(true);
      await api.post('departments', data);
      toast.success('Ministério cadastrado com sucesso');
      history.push(`/department`);
    } catch (err) {
      const errData = err.response.data;
      toast.error(
        errData && errData.error
          ? `Error ao criar ministério: ${errData.error}`
          : 'Error creating department, try again'
      );
      setLoading(false);
    }
  }

  return (
    <Container>
      <TitlePage>Cadastro Ministério</TitlePage>
      <Formik
        initialValues={{
          name: '',
        }}
        validationSchema={schema}
        onSubmit={values => handleSubmit(values)}
      >
        <Form>
          <Field
            name="name"
            placeholder="Título do ministério"
            autoComplete="off"
          />
          <ErrorMessage component="span" name="name" />

          <button type="submit" disabled={loading}>
            Cadastrar ministério
          </button>
        </Form>
      </Formik>
    </Container>
  );
}
