import React, { useEffect, useState, useMemo } from 'react';
import 'antd/dist/antd.css';
import { Icon } from 'antd';

import { FaBirthdayCake } from 'react-icons/fa';
import { Container, ItemDashboard } from './styles';
import api from '~/services/api';

export default function Dashboard() {
  const [staticInfo, setStaticInfo] = useState([]);

  const memoInfo = useMemo(() => staticInfo, [staticInfo]);

  useEffect(() => {
    async function loadingDashBoardItems() {
      const response = await api.get('/items-dashboard');
      setStaticInfo(response.data);
    }
    loadingDashBoardItems();
  }, []);

  return (
    <Container>
      <ItemDashboard to="/dashboard-item/ATIVO" primary="true">
        <Icon type="smile" />
        <div>
          <span>Membros ativos</span>
          <strong>{memoInfo.active || 0}</strong>
        </div>
      </ItemDashboard>
      <ItemDashboard to="/dashboard-item/INATIVO" fourth="true">
        <Icon type="meh" />
        <div>
          <span>Membros inativos</span>
          <strong>{memoInfo.inactive || 0}</strong>
        </div>
      </ItemDashboard>
      <ItemDashboard to="/dashboard-item/AFASTADO" third="true">
        <Icon type="frown" />
        <div>
          <span>Membros afastados</span>
          <strong>{memoInfo.away || 0}</strong>
        </div>
      </ItemDashboard>
      <ItemDashboard to="/dashboard-item/OBITO" fifith="true">
        <Icon type="frown" />
        <div>
          <span>Membros falecidos</span>
          <strong>{memoInfo.dead || 0}</strong>
        </div>
      </ItemDashboard>
      <ItemDashboard to="/dashboard-item/ANIVERSARIO" secondary="true">
        <FaBirthdayCake />
        <div>
          <span>Aniversariantes mês</span>
          <strong>{memoInfo.birthMember || 0}</strong>
        </div>
      </ItemDashboard>
    </Container>
  );
}
