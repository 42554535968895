import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ItemDashboard = styled(Link)`
  background: ${props => (props.primary ? '#27AE60' : '')};
  background: ${props => (props.secondary ? '#2D9CDB' : '')};
  background: ${props => (props.third ? '#EB5757' : '')};
  background: ${props => (props.fourth ? '#F2C94C' : '')};
  background: ${props => (props.fifith ? '#999' : '')};
  color: #fff;
  height: 150px;
  width: 400px;
  display: flex;

  border: 0;
  box-shadow: 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 20px;

  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 425px) {
    width: 200px;
    height: 100px;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  span {
    font-size: 20px;
    font-weight: bold;
    @media (max-width: 425px) {
      font-size: 15px;
    }
  }
  strong {
    font-size: 40px;
    @media (max-width: 425px) {
      font-size: 20px;
    }
  }
  svg {
    font-size: 50px;
    transition: transform 0.2s;
    @media (max-width: 425px) {
      font-size: 30px;
    }
  }

  &:hover {
    border: 1px solid #fff;
    color: #fff;
  }

  &:hover svg {
    transform: scale(1.1);
  }
`;
