import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';

export const Container = styled.div`
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ItemDocumentLink = styled(Link)`
  background: ${lighten(0.3, '#041648')};
  color: #fff;
  min-height: 150px;
  width: 400px;
  display: flex;

  border: 0;
  box-shadow: 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 20px;

  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 425px) {
    width: 200px;
    height: 100px;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }

  strong {
    font-size: 25px;
    @media (max-width: 425px) {
      font-size: 14px;
    }
  }
  svg {
    font-size: 30px;
    transition: transform 0.2s;
  }

  &:hover {
    border: 1px solid #fff;
    color: #fff;
  }

  &:hover svg {
    transform: scale(1.1);
  }

  > a {
    text-decoration: none;
  }
`;

export const ItemDocumentButton = styled.button.attrs(props => ({
  disabled: props.loadingButton,
}))`
  background: ${lighten(0.3, '#041648')};
  color: #fff;
  min-height: 150px;
  width: 400px;
  display: flex;

  border: 0;
  box-shadow: 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 20px;

  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 425px) {
    width: 200px;
    height: 100px;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }

  strong {
    font-size: 25px;
    @media (max-width: 425px) {
      font-size: 14px;
    }
  }
  svg {
    font-size: 30px;
    transition: transform 0.2s;
  }

  &:hover {
    border: 1px solid #fff;
  }

  &:hover svg {
    transform: scale(1.1);
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;
