import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

import history from '~/services/history';
import logo from '~/assets/logo.svg';
import { Container, Content, Profile } from './styles';

export default function Header() {
  const profile = useSelector(state => state.user.profile);

  const {
    location: { pathname },
    goBack,
  } = history;

  return (
    <Container>
      <Content>
        <nav>
          <Link to="/dashboard">
            <img src={logo} alt="iprvenceslau" />
          </Link>

          {pathname !== '/dashboard' && (
            <button type="button" onClick={goBack}>
              <MdArrowBack />
              Voltar
            </button>
          )}
        </nav>

        <Profile>
          <div>
            <strong>{profile.name}</strong>
            <Link to="/profile">Meu perfil</Link>
          </div>
          <img
            src={`${
              profile.file_id
                ? profile.file_id.url
                : 'https://api.adorable.io/avatars/50/abott@adorable.png'
            }`}
            alt="avatarfake"
          />
        </Profile>
      </Content>
    </Container>
  );
}
