import React, { useState } from 'react';

import { FaBaby, FaUsers, FaReceipt, FaRegAddressCard } from 'react-icons/fa';
import { MdWc, MdEmail } from 'react-icons/md';

import { Container, ItemDocumentLink, ItemDocumentButton } from './styles';
import { handleMembersPoll } from '~/util/pdfapi';

export default function Document() {
  const [loadingButton, setLoadingButton] = useState(false);

  return (
    <Container>
      <ItemDocumentLink to="document-kids">
        <FaBaby />
        <div>
          <strong>Certificado de apresentação</strong>
        </div>
      </ItemDocumentLink>
      <ItemDocumentLink to="/document-merriage">
        <MdWc />
        <div>
          <strong>Certificado de casamento</strong>
        </div>
      </ItemDocumentLink>
      <ItemDocumentLink to="/document-transfer">
        <MdEmail size={26} />
        <div>
          <strong>Carta transferência</strong>
        </div>
      </ItemDocumentLink>
      <ItemDocumentLink to="/document-presentation">
        <FaReceipt size={26} />
        <div>
          <strong>Carta apresentação</strong>
        </div>
      </ItemDocumentLink>
      <ItemDocumentLink to="/members-card">
        <FaRegAddressCard size={26} />
        <div>
          <strong>Lote Credencial</strong>
        </div>
      </ItemDocumentLink>
      <ItemDocumentButton
        onClick={async () => {
          setLoadingButton(true);
          await handleMembersPoll();
          setLoadingButton(false);
        }}
        loadingButton={loadingButton ? 1 : 0}
      >
        <FaUsers />
        <strong>Lista membros eleição</strong>
      </ItemDocumentButton>
    </Container>
  );
}
