import styled from 'styled-components';

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  background: linear-gradient(180deg, #1273e5, #f9f9f9);
`;

export const Content = styled.div`
  background: rgba(118, 152, 192, 0.6);
  width: 100%;
`;
