import styled from 'styled-components';

export const Container = styled.div`
  align-self: center;
  margin-bottom: 30px;

  align-self: center;

  label {
    cursor: pointer;

    img {
      height: 200px;
      width: 180px;
      border-radius: 10%;
      object-fit: cover;
      &:hover {
        border: 3px solid #fff;
      }
    }

    input {
      display: none;
    }
  }
`;
