import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  margin: 10px 20px;
  padding: 0 10px 10px 10px;

  div {
    max-width: 800px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;

    input {
      background: #fff;
      border: 0;
      border-radius: 4px;
      height: 44px;
      color: #333;
      margin: 10px 0 0 0;
      border: 2px solid rgba(0, 0, 0, 0.1);
    }
    > span {
      color: #d71b1b;
      align-self: flex-start;
      margin: 10px 0;
      font-size: 12px;
      font-weight: bold;
    }
  }
`;

export const ListNames = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  margin-bottom: 5px;
  background: #fff;
  padding: 0 10px 0 10px;

  button {
    border: 0;
    background: transparent;
  }

  strong {
    font-size: 12px;
    color: black;

    @media (max-width: 425px) {
      font-size: 10px;
    }
  }
`;

export const BtnStyled = styled.button.attrs(props => ({
  disabled: props.loadingButton,
}))`
  height: 44px;
  background: #e67311;
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.2s;
  &:hover {
    background: ${lighten(0.03, '#E67311')};
  }
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export const ListBatch = styled.ul`
  display: flex;
  flex-direction: column;
  height: 250px;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 10px;

  > span {
    color: #fff;
    font-weight: bold;
    font-size: 18px;
  }
`;

export const LoadingWrapper = styled.div`
  margin: 100px 0;
  text-align: center;
`;
