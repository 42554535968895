import React from 'react';
import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import pt from 'date-fns/locale/pt';

import './styles.css';
import 'react-datepicker/dist/react-datepicker.css';

export default function DatePicker({
  name,
  value,
  onChange,
  onBlur,
  placeholder,
}) {
  return (
    <ReactDatePicker
      placeholderText={placeholder}
      defaultValue={null}
      dateFormat="dd/MM/yyyy"
      autoComplete="off"
      locale={pt}
      selected={(value && new Date(value)) || null}
      onChange={val => {
        onChange(name, val);
      }}
      onBlur={val => {
        onBlur(name, val);
      }}
    />
  );
}

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  placeholder: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {
  placeholder: '',
  value: null,
};
