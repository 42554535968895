import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  margin: 10px 20px;
  padding: 0 10px 10px 10px;

  form {
    max-width: 800px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;

    input {
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #fff;
      margin-bottom: 10px;
      border: 2px solid rgba(0, 0, 0, 0.1);
      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
      &:focus {
        border: 2px solid #fff;
      }
    }
    span {
      color: #d71b1b;
      align-self: flex-start;
      margin: 0 0 10px;
      font-size: 12px;
      font-weight: bold;
    }

    > div {
      display: flex;
      flex-direction: row;

      > input {
        flex-grow: 1;
        @media (max-width: 375px) {
          width: 100px;
        }
      }

      > button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        width: 40px;
        height: 44px;
        background: #e67311;
        color: #fff;
        border: 0;
        border-radius: 4px;
        font-size: 16px;
        transition: background 0.2s;
        &:hover {
          background: ${lighten(0.03, '#E67311')};
        }
      }
    }
  }
`;

export const ListNames = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  background: #7698c0;
  padding: 2px;

  strong {
    font-size: 18px;
    color: #fff;
    margin-left: 10px;
  }
`;

export const BtnStyled = styled.button.attrs(props => ({
  disabled: props.loadingButton,
}))`
  height: 44px;
  background: #e67311;
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.2s;
  &:hover {
    background: ${lighten(0.03, '#E67311')};
  }
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;
