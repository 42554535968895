import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function TitlePage({ children }) {
  return (
    <Container>
      <strong>{children}</strong>
      <hr />
    </Container>
  );
}

TitlePage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};
