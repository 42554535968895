import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';

import { storeMemberSchema, initialMember } from '~/util/YupStoreMember';
import {
  phoneNumberMask,
  telNumberMask,
  cepMask,
  stateMask,
  cpfMask,
} from '~/util/masks';
import TitlePage from '~/components/TitlePage';
import DatePicker from '~/components/DatePicker';
import SelectControlled from '~/components/SelectControlled';
import SelectUncontrolled from '~/components/SelectUncontrolled';
import { Container, UserInfo, UserAdress, UserMore } from './styles';

// import AvatarMember from '~/components/AvatarMember';
import AvatarCropp from '~/components/CropImage';

import api from '~/services/api';
import history from '~/services/history';

export default function New() {
  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [churchs, setChurchs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      const responseRoles = await api.get('roles');
      const responseDepartments = await api.get('departments');
      const responseChurchs = await api.get('churchs');

      setRoles(responseRoles.data);
      setDepartments(responseDepartments.data);
      setChurchs(responseChurchs.data);
    }
    loadData();
  }, []);

  async function handleSubmit(data) {
    try {
      setLoading(true);
      await api.post('members', {
        ...data,
        file_id: data.file_id._id,
      });
      toast.success('Membro cadastrado com sucesso');
      history.push(`/member`);
    } catch (err) {
      const errData = err.response;
      toast.error(
        errData && errData.error
          ? `Error ao cadastrar membro: ${errData.error}`
          : 'Error creating member, try again'
      );
      setLoading(false);
    }
  }

  return (
    <Container>
      <TitlePage>Cadastro Membro</TitlePage>

      <Formik
        enableReinitialize
        initialValues={initialMember}
        validationSchema={storeMemberSchema}
        onSubmit={async values => handleSubmit(values)}
      >
        {formik => (
          <Form>
            <Field
              name="file_id"
              render={() => (
                <AvatarCropp
                  name="file_id"
                  value={formik.values.file_id}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                  error={formik.errors.file_id}
                />
              )}
            />
            <UserInfo>
              <strong>Nome</strong>
              <Field name="name" />
              <ErrorMessage component="span" name="name" />

              <div>
                <div>
                  <strong>Nascimento</strong>
                  <Field
                    name="birth"
                    render={() => (
                      <DatePicker
                        name="birth"
                        value={formik.values.birth}
                        onChange={formik.setFieldValue}
                        onBlur={formik.setFieldTouched}
                      />
                    )}
                  />
                  <ErrorMessage component="span" name="birth" />
                </div>

                <div>
                  <strong>Sexo</strong>
                  <Field
                    name="gender"
                    placeholder="Digite o sexo"
                    render={() => (
                      <SelectControlled
                        data={['MASCULINO', 'FEMININO']}
                        id="gender"
                        name="gender"
                        placeholder="Selecione o sexo"
                        value={formik.values.gender}
                        onChange={formik.setFieldValue}
                        onBlur={formik.setFieldTouched}
                      />
                    )}
                  />
                  <ErrorMessage component="span" name="gender" />
                </div>
              </div>

              <div>
                <div>
                  <strong>RG</strong>
                  <Field name="rg" />
                  <ErrorMessage component="span" name="rg" />
                </div>

                <div>
                  <strong>CPF</strong>
                  <Field
                    name="cpf"
                    render={() => (
                      <MaskedInput
                        guide
                        mask={cpfMask}
                        id="cpf"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  />
                  <ErrorMessage component="span" name="cpf" />
                </div>
              </div>
            </UserInfo>

            <UserAdress>
              <strong>Endereço</strong>
              <Field name="adress" />
              <ErrorMessage component="span" name="adress" />

              <div>
                <div>
                  <strong>Bairro</strong>
                  <Field name="district" />
                  <ErrorMessage component="span" name="district" />
                </div>

                <div>
                  <strong>Cep</strong>
                  <Field
                    name="postalCode"
                    render={() => (
                      <MaskedInput
                        mask={cepMask}
                        id="postalCode"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  />
                  <ErrorMessage component="span" name="postalCode" />
                </div>
              </div>

              <div>
                <div>
                  <strong>Cidade</strong>
                  <Field name="city" />
                  <ErrorMessage component="span" name="city" />
                </div>

                <div>
                  <strong>Estado</strong>
                  <Field
                    name="state"
                    render={() => (
                      <MaskedInput
                        guide={false}
                        mask={stateMask}
                        id="state"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  />
                  <ErrorMessage component="span" name="state" />
                </div>
              </div>
            </UserAdress>

            <UserMore>
              <div>
                <div>
                  <strong>Tel residêncial</strong>
                  <Field
                    name="tel"
                    render={() => (
                      <MaskedInput
                        mask={telNumberMask}
                        id="tel"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  />
                  <ErrorMessage component="span" name="tel" />
                </div>

                <div>
                  <strong>Tel celular</strong>
                  <Field
                    name="phone"
                    render={() => (
                      <MaskedInput
                        mask={phoneNumberMask}
                        id="phone"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  />
                  <ErrorMessage component="span" name="phone" />
                </div>
              </div>

              <strong>Email</strong>
              <Field name="email" />
              <ErrorMessage component="span" name="email" />

              <div>
                <div>
                  <strong>Escolaridade</strong>
                  <Field
                    name="scholarity"
                    render={() => (
                      <SelectControlled
                        data={[
                          'EDUCAÇÃO INFANTIL',
                          'FUNDAMENTAL',
                          'MÉDIO',
                          'SUPERIOR',
                          'MESTRADO',
                          'DOUTORADO',
                        ]}
                        id="scholarity"
                        name="scholarity"
                        placeholder="Selecione a escolaridade"
                        value={formik.values.scholarity}
                        onChange={formik.setFieldValue}
                        onBlur={formik.setFieldTouched}
                      />
                    )}
                  />
                  <ErrorMessage component="span" name="scholarity" />
                </div>

                <div>
                  <strong>Trabalho</strong>
                  <Field name="job" />
                  <ErrorMessage component="span" name="job" />
                </div>
              </div>
            </UserMore>

            <UserMore>
              <div>
                <div>
                  <strong>Naturalidade</strong>
                  <Field name="birthCity" />
                  <ErrorMessage component="span" name="birthCity" />
                </div>

                <div>
                  <strong>Naturalidade estado</strong>
                  <Field
                    name="birthState"
                    render={() => (
                      <MaskedInput
                        guide={false}
                        mask={stateMask}
                        id="birthState"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  />
                  <ErrorMessage component="span" name="birthState" />
                </div>
              </div>

              <strong>Nome da mãe</strong>
              <Field name="nameMom" />
              <ErrorMessage component="span" name="nameMom" />

              <strong>Nome do pai</strong>
              <Field name="nameDad" />
              <ErrorMessage component="span" name="nameDad" />
            </UserMore>

            <UserMore>
              <strong>Estado civil</strong>
              <Field
                name="civilStatus"
                render={() => (
                  <SelectControlled
                    data={[
                      'SOLTEIRO',
                      'CASADO',
                      'SEPARADO',
                      'DIVORCIADO',
                      'VIÚVO',
                    ]}
                    id="civilStatus"
                    name="civilStatus"
                    placeholder="Selecione estado civil"
                    value={formik.values.civilStatus}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                  />
                )}
              />
              <ErrorMessage component="span" name="civilStatus" />

              <strong>Nome Cônjuge</strong>
              <Field name="spouse" />
              <ErrorMessage component="span" name="spouse" />

              <strong>Filhos</strong>
              <Field name="children" type="number" />
              <ErrorMessage component="span" name="children" />
            </UserMore>

            <UserMore>
              <strong>Situação do membro</strong>
              <Field
                name="status"
                render={() => (
                  <SelectControlled
                    data={['ATIVO', 'INATIVO', 'AFASTADO', 'OBITO']}
                    id="status"
                    name="status"
                    placeholder="Selecione a situação do membro"
                    value={formik.values.status}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                  />
                )}
              />
              <ErrorMessage component="span" name="status" />

              <strong>Recepção</strong>
              <Field name="reception" />
              <ErrorMessage component="span" name="reception" />

              <div>
                <div>
                  <strong>Data Batismo</strong>
                  <Field
                    name="dateBaptism"
                    render={() => (
                      <DatePicker
                        name="dateBaptism"
                        value={formik.values.dateBaptism}
                        onChange={formik.setFieldValue}
                        onBlur={formik.setFieldTouched}
                      />
                    )}
                  />
                  <ErrorMessage component="span" name="dateBaptism" />
                </div>
                <div>
                  <strong>Data apresentação</strong>
                  <Field
                    name="dateArriveAt"
                    render={() => (
                      <DatePicker
                        name="dateArriveAt"
                        value={formik.values.dateArriveAt}
                        onChange={formik.setFieldValue}
                        onBlur={formik.setFieldTouched}
                      />
                    )}
                  />
                  <ErrorMessage component="span" name="dateArriveAt" />
                </div>
              </div>
            </UserMore>

            <UserMore>
              <strong>Ministério</strong>
              <Field
                name="department"
                render={() => (
                  <SelectUncontrolled
                    placeholder="Selecione o ministério"
                    data={departments}
                    id="department"
                    name="department"
                    value={formik.values.department}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                  />
                )}
              />
              <ErrorMessage component="span" name="department" />

              <strong>Cargo</strong>
              <Field
                name="role"
                render={() => (
                  <SelectUncontrolled
                    placeholder="Selecione o cargo"
                    data={roles}
                    id="role"
                    name="role"
                    value={formik.values.role}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                  />
                )}
              />
              <ErrorMessage component="span" name="role" />

              <strong>Igreja</strong>
              <Field
                name="church"
                render={() => (
                  <SelectUncontrolled
                    placeholder="Selecione a igreja"
                    data={churchs}
                    id="church"
                    name="church"
                    value={formik.values.church}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                  />
                )}
              />
              <ErrorMessage component="span" name="church" />
            </UserMore>
            <button type="submit" disabled={loading}>
              Cadastrar membro
            </button>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
