import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  strong {
    margin-top: 10px;
    font-size: 24px;
    color: #fff;
  }
  hr {
    width: 100%;
    height: 3px;
    margin-top: 10px;
    background: rgba(255, 255, 255, 0.1);
    border: 0;
  }
`;
