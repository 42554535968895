import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import DatePicker from '~/components/DatePicker';
import { Container, BtnStyled } from './styles';
import TitlePage from '~/components/TitlePage';
import { handleMerriageCertificate } from '~/util/pdfapi';

const schema = Yup.object().shape({
  nameMale: Yup.string().required('Nome do homem'),
  nameFemale: Yup.string().required('Nome da mulher'),
  date: Yup.date()
    .typeError('Data do casamento é obrigatório')
    .required('Data do casamento é obrigatório')
    .nullable(),
});

export default function FormMerriage() {
  const [loadingButton, setLoadingButton] = useState(false);

  return (
    <Container>
      <TitlePage>Certificado de Casamento</TitlePage>
      <Formik
        initialValues={{
          nameMale: '',
          nameFemale: '',
          date: null,
        }}
        validationSchema={schema}
        onSubmit={async values => {
          setLoadingButton(true);
          await handleMerriageCertificate(values);
          setLoadingButton(false);
        }}
      >
        {formik => (
          <Form>
            <Field name="nameMale" placeholder="Nome do homem" />
            <ErrorMessage component="span" name="nameMale" />

            <Field name="nameFemale" placeholder="Nome da mulher" />
            <ErrorMessage component="span" name="nameFemale" />

            <Field
              name="date"
              render={() => (
                <DatePicker
                  name="date"
                  placeholder="Data casamento"
                  value={formik.values.date}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                />
              )}
            />
            <ErrorMessage component="span" name="date" />

            <BtnStyled type="submit" loadingButton={loadingButton ? 1 : 0}>
              Gerar documento
            </BtnStyled>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
