import React from 'react';
import PropTypes from 'prop-types';

import blankAvatar from '../../assets/blank-avatar.png';
import whiteCamera from '../../assets/camera-white.png';

import { Container, Figure } from './styles';

const InputAvatar = ({ onChange, croppedImage }) => {
  return (
    <Container>
      <label htmlFor="avatar_id">
        <input
          id="avatar_id"
          type="file"
          accept="image/x-png,image/jpeg"
          onChange={onChange}
          onClick={e => {
            e.target.value = null;
          }}
        />
        <Figure>
          {!croppedImage ? (
            <img src={blankAvatar} alt="avatar" />
          ) : (
            <img src={croppedImage} alt="croppedavatar" />
          )}
          <figcaption>
            <img src={whiteCamera} alt="whitecamera" />
          </figcaption>
        </Figure>
      </label>
    </Container>
  );
};

InputAvatar.propTypes = {
  onChange: PropTypes.func.isRequired,
  croppedImage: PropTypes.string,
};

InputAvatar.defaultProps = {
  croppedImage: '',
};

export default InputAvatar;
