import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const Container = styled.div`
  margin: 10px 20px;
  padding: 10px 10px;

  strong {
    color: #333;
    font-size: 16px;
    margin: 0 0 0 10px;
  }

  span {
    font-size: 12px;
    color: #333;
    margin-right: 5px;
  }
`;

export const ActionMember = styled.div`
  max-width: 800px;
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const ActionItem = styled.button.attrs(props => ({
  disabled: props.loadingButton,
}))`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 80px;
  width: 280px;

  background: #e67311;
  padding: 5px;
  border-radius: 8px;

  transition: background 0.2s;

  margin: 3px 3px;

  > span {
    color: #fff;
    font-weight: bold;
    font-size: 12px;
  }

  > svg {
    color: #fff;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover {
    background: ${darken(0.02, '#e67311')};
  }

  @media (max-width: 768px) {
    width: 200px;
  }
`;

export const WrapperMember = styled.div`
  max-width: 800px;
  margin: 10px auto;
  display: flex;
  flex-direction: row;

  @media (max-width: 425px) {
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    img {
      margin: 10px 0;
    }
  }

  img {
    object-fit: cover;
    height: 200px;
    width: 180px;
    border-radius: 10%;
    border: 2px solid rgba(255, 255, 255, 0.3);
    background: #e8e8e8;
    margin-right: 10px;
  }

  > div {
    display: flex;
    flex-grow: 1;

    @media (max-width: 425px) {
      flex-direction: column;
      flex-grow: 0;
      width: 235px;
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  margin-left: 5px;

  @media (max-width: 425px) {
    margin: 10px 0 0 0;
    flex-direction: row;
  }

  svg,
  a {
    color: ${lighten(0.1, '#333')};
    cursor: pointer;
    margin: 5px 10px;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const WrapperPrimary = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  align-items: stretch;
  justify-content: center;
  flex-grow: 2;
  flex-shrink: 2;
  padding: 10px;

  svg {
    margin: 0 5px;
    color: #333;
  }

  @media (max-width: 425px) {
    height: 300px;
  }
`;

export const WrapperTab = styled.div`
  display: flex;
  max-width: 800px;
  margin: 10px auto;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  height: 350px;

  @media (max-width: 425px) {
    width: 235px;
  }
`;

export const InfoMember = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
`;

export const LoadingWrapper = styled.div`
  margin: 100px 0;
  text-align: center;
`;
