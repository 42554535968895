import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Icon, Tooltip, Pagination, Popconfirm, Input } from 'antd';
import {
  FaTrash,
  FaPen,
  FaRegEye,
  FaWhatsapp,
  FaPhone,
  FaChurch,
  FaCalendarAlt,
  FaHome,
  FaCity,
  FaPlusCircle,
} from 'react-icons/fa';
import { format } from 'date-fns';

import {
  Container,
  ItemMember,
  ActionContainer,
  LoadingWrapper,
  UserContact,
  UserInfo,
  UserAdress,
  WrapperHeader,
} from './styles';
import TitlePage from '~/components/TitlePage';
import Alphabet from '~/components/Alphabet';

import api from '~/services/api';
import './antdInput.css';

const { Search } = Input;

export default function Member() {
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');

  async function loadingMembers() {
    setSearch('');
    const response = await api.get('members');
    setTotal(response.data.totalDocs);
    setMembers(response.data.docs);
    setLoading(false);
  }

  useEffect(() => {
    loadingMembers();
  }, []);

  async function handlePagination(page) {
    setLoading(true);
    const response = await api.get('members', {
      params: {
        page,
        search,
      },
    });
    setTotal(response.data.totalDocs);
    setMembers(response.data.docs);
    setLoading(false);
  }

  async function handleDelete(id) {
    await api.delete(`members/${id}`);
    setMembers(members.filter(member => member._id !== id));
    toast.success('Membro excluido com sucesso');
  }

  async function handleSearchAlphabet(letter) {
    setLoading(true);
    const response = await api.get('members', {
      params: {
        search: letter,
      },
    });
    setSearch(letter);
    setTotal(response.data.totalDocs);
    setMembers(response.data.docs);
    setLoading(false);
  }

  return (
    <Container>
      <TitlePage>Membros</TitlePage>
      <WrapperHeader>
        <Link to="/member-add">
          <FaPlusCircle />
          Membro
        </Link>
        <Search
          allowClear
          placeholder="Nome ou identificação"
          onSearch={value => handleSearchAlphabet(value)}
          style={{ height: 44, width: '90%', margin: '10px 10px 0 10px' }}
        />
        <div>
          <button type="button" onClick={loadingMembers}>
            Todos
          </button>
          <button
            type="button"
            onClick={() => handleSearchAlphabet('last-members')}
          >
            Ultimos
          </button>
        </div>
      </WrapperHeader>
      <Alphabet getItem={value => handleSearchAlphabet(value)} />
      {loading ? (
        <LoadingWrapper>
          <Icon type="loading" style={{ color: '#fff', fontSize: '50px' }} />
        </LoadingWrapper>
      ) : (
        <ul>
          {members.map(member => (
            <ItemMember key={member._id}>
              <UserInfo>
                <strong>
                  {member.identity} -{' '}
                  {member.church ? (
                    <strong>
                      <FaChurch />
                      {member.church.name}
                    </strong>
                  ) : (
                    <strong>
                      <FaChurch />
                      Sem igreja
                    </strong>
                  )}
                </strong>

                <strong>{member.name}</strong>
                <strong>
                  <FaCalendarAlt />
                  {format(new Date(member.birth), 'dd/MM/yyyy')}
                </strong>
              </UserInfo>
              <UserContact>
                {member.phone ? (
                  <strong>
                    <FaWhatsapp />
                    {member.phone}
                  </strong>
                ) : (
                  ''
                )}
                <strong>
                  <FaPhone />
                  {member.tel}
                </strong>
              </UserContact>
              <UserAdress>
                <strong>
                  <FaHome />
                  {member.adress}
                </strong>
                <strong>
                  <FaCity />
                  {member.city} - {member.state}
                </strong>
              </UserAdress>
              <ActionContainer>
                <Tooltip title="Visualizar">
                  <Link to={`/member-show/${member._id}`}>
                    <FaRegEye size={16} />
                  </Link>
                </Tooltip>
                <Tooltip title="Editar">
                  <Link to={`/member-edit/${member._id}`}>
                    <FaPen size={16} />
                  </Link>
                </Tooltip>
                <Popconfirm
                  onConfirm={() => handleDelete(member._id)}
                  title="Deseja excluir membro?"
                  cancelText="Cancelar"
                  okText="Confirmar"
                  icon={
                    <Icon type="question-circle-o" style={{ color: 'red' }} />
                  }
                >
                  <FaTrash size={16} />
                </Popconfirm>
              </ActionContainer>
            </ItemMember>
          ))}
        </ul>
      )}
      <Pagination
        onChange={page => handlePagination(page)}
        defaultCurrent={1}
        total={total}
        pageSize={8}
      />
    </Container>
  );
}
