import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  border-radius: 5px;
  margin: 10px 20px;
  background: #7698c0;
  padding: 0 10px 10px 10px;

  form {
    max-width: 800px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;

    select {
      border: 2px solid rgba(0, 0, 0, 0.1);
      background-color: rgba(0, 0, 0, 0.1);
      margin-bottom: 10px;
      height: 44px;
      border-radius: 4px;
      padding: 0 10px;
      color: #fff;
      display: block;
      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
      option {
        color: black;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
      &:focus {
        border: 2px solid #fff;
      }
    }

    input {
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #fff;
      margin-bottom: 10px;
      border: 2px solid rgba(0, 0, 0, 0.1);
      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
      &:focus {
        border: 2px solid #fff;
      }
    }
    span {
      color: #d71b1b;
      align-self: flex-start;
      margin: 0 0 10px;
      font-size: 12px;
      font-weight: bold;
    }

    button {
      height: 44px;
      background: #e67311;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;

      &:hover {
        background: ${lighten(0.03, '#E67311')};
      }

      :disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    a {
      color: #fff;
      margin-top: 15px;
      font-size: 16px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
`;
