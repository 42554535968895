import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import api from '~/services/api';

import { Container } from './styles';

export default function AvatarInput({ onChange, field }) {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (field) {
      setPreview(field.value.url);
    }
  }, [field]);

  async function handleChange(e) {
    const data = new FormData();

    data.append('file', e.target.files[0]);

    const response = await api.post('files', data);

    const { id, url } = response.data;

    setFile(id);
    setPreview(url);
    onChange(response.data);
  }

  return (
    <Container>
      <label htmlFor="avatar">
        <img
          src={
            preview || 'https://api.adorable.io/avatars/50/abott@adorable.png'
          }
          alt=""
        />
        <input
          type="file"
          id="avatar"
          data-file={file}
          accept="image/*"
          onChange={handleChange}
        />
      </label>
    </Container>
  );
}

AvatarInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  field: PropTypes.shape({
    value: PropTypes.object,
    url: PropTypes.string,
  }),
};

AvatarInput.defaultProps = {
  field: {},
};
