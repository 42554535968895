import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Icon, Tooltip, Pagination, Popconfirm } from 'antd';
import { FaNetworkWired, FaTrash, FaPen } from 'react-icons/fa';

import api from '~/services/api';
import {
  Container,
  ItemRole,
  ActionContainer,
  LoadingWrapper,
  ContentWrapper,
} from './styles';
import TitlePage from '~/components/TitlePage';

export default function Role() {
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    async function loadDepartments() {
      const response = await api.get('roles', {
        params: {
          page: 1,
        },
      });
      setTotal(response.data.totalDocs);
      setRoles(response.data.docs);
      setLoading(false);
    }

    loadDepartments();
  }, []);

  async function handlePagination(page) {
    setLoading(true);
    const response = await api.get('roles', {
      params: {
        page,
      },
    });
    setTotal(response.data.totalDocs);
    setRoles(response.data.docs);
    setLoading(false);
  }

  async function handleDelete(id) {
    await api.delete(`roles/${id}`);
    setRoles(roles.filter(role => role._id !== id));
    toast.success('Cargo excluido com sucesso');
  }

  return (
    <Container>
      <TitlePage>Cargos</TitlePage>
      <Link to="/role-add">Novo cargo</Link>
      <ContentWrapper>
        {loading ? (
          <LoadingWrapper>
            <Icon type="loading" style={{ color: '#fff', fontSize: '50px' }} />
          </LoadingWrapper>
        ) : (
          <ul>
            {roles.map(role => (
              <ItemRole key={role._id}>
                <strong>
                  <FaNetworkWired size={16} />
                  {role.name}
                </strong>
                <ActionContainer>
                  <Tooltip title="Editar">
                    <Link to={`/role-edit/${role._id}`}>
                      <FaPen size={16} />
                    </Link>
                  </Tooltip>
                  <Popconfirm
                    onConfirm={() => handleDelete(role._id)}
                    title="Deseja excluir cargo?"
                    cancelText="Cancelar"
                    okText="Confirmar"
                    icon={
                      <Icon type="question-circle-o" style={{ color: 'red' }} />
                    }
                  >
                    <FaTrash size={16} />
                  </Popconfirm>
                </ActionContainer>
              </ItemRole>
            ))}
          </ul>
        )}
        <Pagination
          onChange={page => handlePagination(page)}
          defaultCurrent={1}
          total={total}
          pageSize={5}
        />
      </ContentWrapper>
    </Container>
  );
}
