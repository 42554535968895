import React, { useState, useEffect } from 'react';
import { Icon, Pagination, Input } from 'antd';
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';

import {
  Container,
  ListNames,
  BtnStyled,
  ListBatch,
  LoadingWrapper,
} from './styles';
import TitlePage from '~/components/TitlePage';
import { handleMemberCardsBatch } from '~/util/pdfapi';
import api from '~/services/api';

const { Search } = Input;

export default function FormMembersCard() {
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [members, setMembers] = useState([]);
  const [membersCard, setMembersCard] = useState([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('last-members');
  const [error, setError] = useState('');

  useEffect(() => {
    async function loadingMembers() {
      setSearch('');
      const response = await api.get('members', {
        params: {
          search: 'last-members',
        },
      });
      setTotal(response.data.totalDocs);
      setMembers(response.data.docs);
      setLoading(false);
    }
    loadingMembers();
  }, []);

  async function handleSearchAlphabet(letter) {
    setLoading(true);
    if (letter === '') {
      letter = 'last-members';
    }
    const response = await api.get('members', {
      params: {
        search: letter,
      },
    });
    setSearch(letter);
    setTotal(response.data.totalDocs);
    setMembers(response.data.docs);
    setLoading(false);
  }

  async function handlePagination(page) {
    setLoading(true);
    const response = await api.get('members', {
      params: {
        page,
        search,
      },
    });
    setTotal(response.data.totalDocs);
    setMembers(response.data.docs);
    setLoading(false);
  }

  function handleAddMember(member) {
    if (membersCard.length <= 3) {
      if (!membersCard.find(item => item.identity === member.identity)) {
        setError('');
        setMembersCard([...membersCard, member]);
      } else {
        setError('Membro não pode ser duplicado');
      }
    } else {
      setError('Máximo 4 membros por lote');
    }
  }

  function handleDelete(id) {
    setError('');
    setMembersCard(membersCard.filter(item => item.identity !== id));
  }

  async function handleDocument() {
    if (membersCard.length > 0) {
      setError('');
      setLoadingButton(true);
      await handleMemberCardsBatch(membersCard);
      setLoadingButton(false);
    } else {
      setError('Adicione um membro');
    }
  }

  return (
    <Container>
      <TitlePage>Lote de Credenciais</TitlePage>
      <div>
        <Search
          allowClear
          placeholder="Nome ou identificação"
          onSearch={value => handleSearchAlphabet(value)}
        />
        {loading ? (
          <LoadingWrapper>
            <Icon type="loading" style={{ color: '#fff', fontSize: '50px' }} />
          </LoadingWrapper>
        ) : (
          <ul>
            {members.map(member => (
              <ListNames key={member.identity}>
                <strong>
                  {member.identity} - {member.name}
                </strong>
                <button type="button" onClick={() => handleAddMember(member)}>
                  <FaPlusCircle />
                </button>
              </ListNames>
            ))}
          </ul>
        )}
        <Pagination
          onChange={page => handlePagination(page)}
          defaultCurrent={1}
          total={total}
          pageSize={8}
        />
        <span>{error}</span>

        <ListBatch>
          <span>Lote Credencial</span>
          {membersCard.map(mc => (
            <ListNames key={mc.identity}>
              <strong>
                {mc.identity} - {mc.name}
              </strong>
              <button type="button" onClick={() => handleDelete(mc.identity)}>
                <FaTrashAlt />
              </button>
            </ListNames>
          ))}
        </ListBatch>

        <BtnStyled
          type="submit"
          loadingButton={loadingButton ? 1 : 0}
          onClick={() => handleDocument()}
        >
          Gerar documento
        </BtnStyled>
      </div>
    </Container>
  );
}
