import React from 'react';
import PropTypes from 'prop-types';

export default function Select({
  data,
  value,
  name,
  onChange,
  onBlur,
  placeholder,
}) {
  return (
    <select
      id={name}
      name={name}
      onChange={e => {
        onChange(name, e.target.value);
      }}
      onBlur={val => {
        onBlur(name, val);
      }}
      defaultValue={value}
    >
      <option value="" label={placeholder} />
      {data.map(item => (
        <option key={item} value={item} label={item} />
      ))}
    </select>
  );
}

Select.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

Select.defaultProps = {
  placeholder: '',
  data: [],
  value: null,
};
