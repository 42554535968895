import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';

import { Container } from './styles';
import TitlePage from '~/components/TitlePage';
import { telNumberMask, cepMask, stateMask } from '~/util/masks';
import api from '~/services/api';
import history from '~/services/history';

const schema = Yup.object().shape({
  name: Yup.string().required('Título é obrigatório'),
  adress: Yup.string().required('Endereço é obrigatório'),
  district: Yup.string().required('Bairro é obrigatório'),
  city: Yup.string().required('Cidade é obrigatória'),
  state: Yup.string()
    .min(2, 'Estado possui dois caracteres ex: SP')
    .required('Estádo é obrigatório'),
  postalCode: Yup.string().required('Cep é obrigatório'),
  tel: Yup.string().required('Telefone é obrigatório'),
  member_id: Yup.string().required('Selecione o pastor'),
});

export default function NewChurch() {
  const [loading, setLoading] = useState(false);
  const [shephers, setShephers] = useState([]);

  useEffect(() => {
    async function loadShepherd() {
      const response = await api.get('/shepherd');
      setShephers(response.data);
    }
    loadShepherd();
  }, []);

  async function handleSubmit(data) {
    try {
      setLoading(true);
      await api.post('churchs', data);
      toast.success('Igreja cadastrada com sucesso');
      history.push('/church');
    } catch (err) {
      const errData = err.response.data;
      toast.error(
        errData && errData.error
          ? `Error ao criar igreja: ${errData.error}`
          : 'Error creating church, try again'
      );
      setLoading(false);
    }
  }

  return (
    <Container>
      <TitlePage>Cadastro Igreja</TitlePage>
      <Formik
        enableReinitialize
        initialValues={{
          name: '',
          adress: '',
          district: '',
          city: '',
          state: '',
          postalCode: '',
          tel: '',
          member_id: '',
        }}
        validationSchema={schema}
        onSubmit={values => handleSubmit(values)}
      >
        {formik => (
          <Form>
            <Field
              name="member_id"
              render={() => (
                <select
                  name="member_id"
                  value={formik.values.member_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option
                    value=""
                    defaultValue
                    disabled
                    label="Selecionar o pastor"
                  />
                  {shephers.map(shepherd => (
                    <option
                      key={shepherd._id}
                      value={shepherd._id}
                      label={shepherd.name}
                    />
                  ))}
                </select>
              )}
            />
            <ErrorMessage component="span" name="member_id" />
            <Field name="name" placeholder="Título igreja" autoComplete="off" />
            <ErrorMessage component="span" name="name" />
            <Field name="adress" placeholder="Endereço" autoComplete="off" />
            <ErrorMessage component="span" name="adress" />
            <Field name="district" placeholder="Bairro" autoComplete="off" />
            <ErrorMessage component="span" name="district" />
            <Field name="city" placeholder="Cidade" autoComplete="off" />
            <ErrorMessage component="span" name="city" />
            <Field
              autoComplete="off"
              name="state"
              render={() => (
                <MaskedInput
                  guide={false}
                  mask={stateMask}
                  id="state"
                  placeholder="Estado"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              )}
            />
            <ErrorMessage component="span" name="state" />
            <Field
              autoComplete="off"
              name="postalCode"
              render={() => (
                <MaskedInput
                  mask={cepMask}
                  id="postalCode"
                  placeholder="Cep"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              )}
            />
            <ErrorMessage component="span" name="postalCode" />
            <Field
              autoComplete="off"
              name="tel"
              render={() => (
                <MaskedInput
                  mask={telNumberMask}
                  id="tel"
                  placeholder="Telefone"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              )}
            />
            <ErrorMessage component="span" name="tel" />

            <button type="submit" disabled={loading}>
              Cadastrar igreja
            </button>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
