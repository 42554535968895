import React from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { signOut } from '~/store/modules/auth/actions';
import { Container, WrapperButton } from './styles';
import Avatar from '~/components/Avatar';

import { updateProfileResquest } from '~/store/modules/user/actions';

const schema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Mínimo 3 caracteres')
    .required('Nome é obrigatório'),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  oldPassword: Yup.string(),
  password: Yup.string().when('oldPassword', (oldPassword, field) =>
    oldPassword
      ? field
          .required('Nova senha é obrigatória')
          .min(6, 'A senha deve possuir 6 caracteres')
      : field
  ),
  confirmPassword: Yup.string().when('password', (password, field) =>
    password
      ? field
          .required('Nova senha é obrigatória')
          .oneOf([Yup.ref('password'), null], 'Senhas devem ser iguais')
      : field
  ),
});

export default function Profile() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={{
          file_id: profile.file_id ? profile.file_id : '',
          name: profile.name,
          email: profile.email,
          password: '',
          oldPassword: '',
          confirmPassword: '',
        }}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          dispatch(updateProfileResquest(values));
          resetForm();
        }}
      >
        {formik => (
          <Form>
            <Field
              component={Avatar}
              name="file_id"
              onChange={id => formik.setFieldValue('file_id', id)}
            />
            <Field name="name" placeholder="Nome completo" />
            <ErrorMessage component="span" name="name" />
            <hr />
            <Field name="email" type="email" placeholder="Seu e-mail" />
            <ErrorMessage component="span" name="email" />
            <Field
              name="oldPassword"
              type="password"
              placeholder="Sua senha atual"
            />
            <ErrorMessage component="span" name="oldPassword" />
            <Field name="password" type="password" placeholder="Nova senha" />
            <ErrorMessage component="span" name="password" />
            <Field
              name="confirmPassword"
              type="password"
              placeholder="Confirmação nova senha"
            />
            <ErrorMessage component="span" name="confirmPassword" />
            <button type="submit">Salvar perfil</button>
          </Form>
        )}
      </Formik>
      <WrapperButton>
        <button type="button" onClick={handleSignOut}>
          Sair
        </button>
      </WrapperButton>
    </Container>
  );
}
