import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin: 10px 10px 0 10px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const ItemAlphabet = styled.button`
  color: #fff;
  font-size: 15px;
  border: 0;
  background: transparent;
  font-weight: bold;
  padding: 5px;
  margin: 5px;

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
`;
