import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  border-radius: 5px;
  margin: 10px 10px;
  background: #7698c0;

  form {
    max-width: 800px;
    margin: 30px auto;
    display: flex;
    flex-direction: column;

    > div {
      :nth-child(8) {
        border: 0;
      }
    }

    @media (max-width: 425px) {
      width: 280px;
    }

    span {
      color: #d71b1b;
      align-self: flex-start;
      margin: 0 0 10px;
      font-size: 12px;
      font-weight: bold;
    }

    hr {
      border: 0;
      height: 1px;
      background: rgba(255, 255, 255, 0.2);
      margin: 10px 0;
    }

    select {
      border: 2px solid rgba(0, 0, 0, 0.1);
      background-color: rgba(0, 0, 0, 0.1);
      margin-bottom: 10px;
      height: 44px;
      border-radius: 4px;
      padding: 0 10px;
      color: #fff;
      display: block;
      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
      option {
        color: black;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
      &:focus {
        border: 2px solid #fff;
      }
    }

    > button {
      margin: 0 5px 20px 5px;
      height: 44px;
      background: #e67311;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;
      &:hover {
        background: ${lighten(0.03, '#E67311')};
      }
    }

    input {
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #fff;
      margin-bottom: 10px;
      border: 2px solid rgba(0, 0, 0, 0.1);
      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
      &:focus {
        border: 2px solid #fff;
      }
    }

    strong {
      font-size: 14px;
      color: #eee;
      margin: 0 0 5px 3px;
      font-weight: bold;
    }
  }
`;

export const UserInfo = styled.div`
  padding: 5px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: flex-start;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);

  > div {
    display: flex;
    flex-direction: row;

    @media (max-width: 425px) {
      flex-direction: column;
    }

    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      & + div {
        margin: 0 0 0 10px;

        @media (max-width: 425px) {
          margin: 0;
        }
      }
    }
  }
`;

export const UserAdress = styled.div`
  padding: 5px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: flex-start;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);

  > div {
    display: flex;
    flex-direction: row;
    @media (max-width: 425px) {
      flex-direction: column;
    }

    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      & + div {
        margin: 0 0 0 10px;

        @media (max-width: 425px) {
          margin: 0;
        }
      }
    }
  }
`;

export const UserMore = styled.div`
  padding: 5px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: flex-start;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);

  > div {
    display: flex;
    flex-direction: row;
    @media (max-width: 425px) {
      flex-direction: column;
    }

    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      & + div {
        margin: 0 0 0 10px;

        @media (max-width: 425px) {
          margin: 0;
        }
      }
    }
  }
`;

export const LoadingWrapper = styled.div`
  padding: 100px 0;
  text-align: center;
`;
