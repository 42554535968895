import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Icon } from 'antd';
import 'antd/dist/antd.css';

const { SubMenu } = Menu;
const { Sider } = Layout;

export default function Sidebar() {
  const [collapsed, setCollapsed] = useState(true);

  function onCollapse(cl) {
    setCollapsed(cl);
  }

  return (
    <>
      <Sider
        style={{ minHeight: '93vh' }}
        theme="light"
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <Menu theme="light" defaultSelectedKeys={['1']} mode="inline">
          <SubMenu
            key="sub1"
            title={
              <span>
                <Icon type="appstore" theme="twoTone" twoToneColor="#449aff" />
                <span>Cadastros</span>
              </span>
            }
          >
            <Menu.Item key="1">
              <Link to="/church">
                <Icon type="home" />
                Igrejas
              </Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/department">
                <Icon type="apartment" />
                Ministérios
              </Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/role">
                <Icon type="solution" />
                Cargos
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="5">
            <Link to="/member">
              <Icon type="usergroup-add" style={{ color: '#449aff' }} />
              <span>Membros</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="6">
            <Link to="/document">
              <Icon type="file-text" style={{ color: '#449aff' }} />
              <span>Gerar documentos</span>
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
    </>
  );
}
