import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tabs, Icon, Popconfirm, Tooltip } from 'antd';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { GiDiploma } from 'react-icons/gi';
import {
  FaAddressCard,
  FaWhatsapp,
  FaPhone,
  FaCircle,
  FaRegAddressBook,
  FaBible,
  FaUsers,
  FaTrash,
  FaPen,
} from 'react-icons/fa';

import {
  Container,
  ActionMember,
  ActionItem,
  WrapperMember,
  WrapperTab,
  InfoMember,
  LoadingWrapper,
  ActionContainer,
  WrapperPrimary,
} from './styles';

import api from '~/services/api';
import history from '~/services/history';
import {
  handleMemberRecord,
  handleMemberCard,
  handleMemberCertificate,
} from '~/util/pdfapi';

export default function Show({ match }) {
  const { id } = match.params;
  const { TabPane } = Tabs;
  const [member, setMember] = useState({});
  const [memberFileUrl, setMemberFileUrl] = useState('');
  const [birth, setBirth] = useState(null);
  const [dateBaptism, setDateBaptism] = useState(null);
  const [dateArriveAt, setDateArriveAt] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    async function loadMember() {
      const response = await api.get(`members/${id}`);
      if (response.data.file_id) {
        setMemberFileUrl(response.data.file_id.url);
      }
      if (response.data.birth) {
        setBirth(
          format(
            new Date(utcToZonedTime(response.data.birth, timezone)),
            'dd/MM/yyyy'
          )
        );
      }
      if (response.data.dateBaptism) {
        setDateBaptism(
          format(
            new Date(utcToZonedTime(response.data.dateBaptism, timezone)),
            'dd/MM/yyyy'
          )
        );
      }
      if (response.data.dateArriveAt) {
        setDateArriveAt(
          format(
            new Date(utcToZonedTime(response.data.dateArriveAt, timezone)),
            'dd/MM/yyyy'
          )
        );
      }
      setMember(response.data);
      setLoading(false);
    }
    loadMember();
  }, [id, timezone]);

  async function handleDelete() {
    await api.delete(`members/${id}`);
    toast.success('Membro excluido com sucesso');
    history.push('/member');
  }

  return (
    <Container>
      {loading ? (
        <LoadingWrapper>
          <Icon type="loading" style={{ color: '#fff', fontSize: '50px' }} />
        </LoadingWrapper>
      ) : (
        <>
          <ActionMember>
            <ActionItem
              loadingButton={loadingButton ? 1 : 0}
              onClick={async () => {
                setLoadingButton(true);
                await handleMemberRecord(id);
                setLoadingButton(false);
              }}
            >
              <FaRegAddressBook size={26} />
              <span>Relatório membro</span>
            </ActionItem>
            <ActionItem
              loadingButton={loadingButton ? 1 : 0}
              onClick={async () => {
                setLoadingButton(true);
                await handleMemberCard(id);
                setLoadingButton(false);
              }}
            >
              <FaAddressCard size={26} />
              <span>Credencial membro</span>
            </ActionItem>
            <ActionItem
              loadingButton={loadingButton ? 1 : 0}
              onClick={async () => {
                setLoadingButton(true);
                await handleMemberCertificate(id);
                setLoadingButton(false);
              }}
            >
              <GiDiploma size={26} />
              <span>Certidão batismo</span>
            </ActionItem>
          </ActionMember>
          <WrapperMember>
            <img
              src={
                memberFileUrl ||
                'https://api.adorable.io/avatars/50/abott@adorable.png'
              }
              alt=""
            />

            <div>
              <WrapperPrimary>
                <div>
                  {member.role ? (
                    <strong>
                      {member.role.name === 'PASTOR' ? (
                        <FaBible size={16} />
                      ) : (
                        <FaUsers />
                      )}
                      {member.role.name}
                    </strong>
                  ) : (
                    ''
                  )}

                  <strong>
                    {member.status}
                    {member.status === 'ATIVO' ? (
                      <FaCircle color="green" size={16} />
                    ) : (
                      <FaCircle color="red" size={16} />
                    )}
                  </strong>
                </div>
                <strong>
                  {member.identity} - {member.name}
                </strong>
                <strong>
                  <span>Nascimento:</span>
                  {birth}
                </strong>
                <strong>
                  <span>RG:</span>
                  {member.rg}
                </strong>
                <strong>
                  <span>CPF:</span>
                  {member.cpf}
                </strong>
                <strong>
                  <FaPhone size={16} /> {member.tel}
                </strong>
                <strong>
                  <FaWhatsapp size={16} /> {member.phone}
                </strong>
                <strong>
                  <span>email: </span>
                  {member.email}
                </strong>
              </WrapperPrimary>
              <ActionContainer>
                <Tooltip title="Editar">
                  <Link to={`/member-edit/${member._id}`}>
                    <FaPen size={16} />
                  </Link>
                </Tooltip>
                <Popconfirm
                  onConfirm={() => handleDelete()}
                  title="Deseja excluir membro?"
                  cancelText="Cancelar"
                  okText="Confirmar"
                  icon={
                    <Icon type="question-circle-o" style={{ color: 'red' }} />
                  }
                >
                  <FaTrash size={16} />
                </Popconfirm>
              </ActionContainer>
            </div>
          </WrapperMember>
          <WrapperTab>
            <Tabs defaultActiveKey="1">
              <TabPane size="large" tab="Endereço" key="1">
                <InfoMember>
                  <strong>{member.adress}</strong>
                  <strong>
                    {member.city} - {member.state}
                  </strong>
                  <strong>{member.district}</strong>
                  <strong>{member.postalCode}</strong>
                </InfoMember>
              </TabPane>
              <TabPane tab="Info pessoais" key="2">
                <InfoMember>
                  <strong>
                    <span>Escolaridade: </span>
                    {member.scholarity}
                  </strong>
                  <strong>
                    <span>Trabalho: </span>
                    {member.job}
                  </strong>
                  <strong>
                    <span>Cidade nascimento: </span>
                    {member.birthCity}
                  </strong>
                  <strong>
                    <span>Estado nascimento: </span>
                    {member.birthState}
                  </strong>
                  <strong>
                    <span>Mãe: </span>
                    {member.nameMom}
                  </strong>
                  <strong>
                    <span>Pai: </span>
                    {member.nameDad}
                  </strong>
                  <strong>
                    <span>Estado civil: </span>
                    {member.civilStatus}
                  </strong>
                  <strong>
                    <span>Conjugue: </span>
                    {member.spouse}
                  </strong>
                  <strong>
                    <span>Filhos: </span>
                    {member.children}
                  </strong>
                </InfoMember>
              </TabPane>
              <TabPane tab="Info igreja" key="3">
                <InfoMember>
                  {member.church ? <strong>{member.church.name}</strong> : ''}
                  {member.department ? (
                    <strong>
                      <span>Ministério: </span>
                      {member.department.name}
                    </strong>
                  ) : (
                    ''
                  )}
                  {member.role ? (
                    <strong>
                      <span>Cargo: </span>
                      {member.role.name}
                    </strong>
                  ) : (
                    ''
                  )}
                  <strong>
                    <span>Recepção: </span>
                    {member.reception}
                  </strong>
                  <strong>
                    <span>Data batismo: </span>
                    {dateBaptism}
                  </strong>
                  <strong>
                    <span>Data apresentação: </span>
                    {dateArriveAt}
                  </strong>
                </InfoMember>
              </TabPane>
            </Tabs>
          </WrapperTab>
        </>
      )}
    </Container>
  );
}

Show.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
