import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';

import { Container } from './styles';
import SelectUncontrolled from '~/components/SelectUncontrolled';
import TitlePage from '~/components/TitlePage';
import { telNumberMask, cepMask, stateMask } from '~/util/masks';
import api from '~/services/api';
import history from '~/services/history';

const schema = Yup.object().shape({
  name: Yup.string().required('Título é obrigatório'),
  adress: Yup.string().required('Endereço é obrigatório'),
  district: Yup.string().required('Bairro é obrigatório'),
  city: Yup.string().required('Cidade é obrigatória'),
  state: Yup.string()
    .min(2, 'Estado possui dois caracteres ex: SP')
    .required('Estádo é obrigatório'),
  postalCode: Yup.string().required('Cep é obrigatório'),
  tel: Yup.string().required('Telefone é obrigatório'),
  member_id: Yup.string().required('Selecione o pastor'),
});

export default function EditChurch({ match }) {
  const { id } = match.params;
  const [loading, setLoading] = useState(false);
  const [church, setChurch] = useState({});
  const [shephers, setShephers] = useState([]);
  const [memberId, setMemberId] = useState('');

  useEffect(() => {
    async function loadShepherd() {
      const response = await api.get('/shepherd');
      setShephers(response.data);
      const responseChurch = await api.get(`/churchs/${id}`);
      if (responseChurch.data.member_id) {
        setMemberId(responseChurch.data.member_id._id);
      }
      setChurch(responseChurch.data);
    }
    loadShepherd();
  }, [id]);

  async function handleSubmit(data) {
    try {
      setLoading(true);
      await api.put(`/churchs/${id}`, data);
      toast.success('Igreja alterada com sucesso');
      history.push('/church');
    } catch (err) {
      const errData = err.response.data;
      toast.error(
        errData && errData.error
          ? `Error ao criar igreja: ${errData.error}`
          : 'Error creating church, try again'
      );
      setLoading(false);
    }
  }

  return (
    <Container>
      <TitlePage>Editar Igreja</TitlePage>
      <Formik
        enableReinitialize
        initialValues={{
          name: church.name || '',
          adress: church.adress || '',
          district: church.district || '',
          city: church.city || '',
          state: church.state || '',
          postalCode: church.postalCode || '',
          tel: church.tel || '',
          member_id: memberId || '',
        }}
        validationSchema={schema}
        onSubmit={values => handleSubmit(values)}
      >
        {formik => (
          <Form>
            <Field
              name="member_id"
              render={() => (
                <SelectUncontrolled
                  placeholder="Selecione o pastor"
                  data={shephers}
                  name="member_id"
                  value={formik.values.member_id}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                />
              )}
            />
            <ErrorMessage component="span" name="member_id" />
            <Field name="name" placeholder="Título igreja" autoComplete="off" />
            <ErrorMessage component="span" name="name" />
            <Field name="adress" placeholder="Endereço" autoComplete="off" />
            <ErrorMessage component="span" name="adress" />
            <Field name="district" placeholder="Bairro" autoComplete="off" />
            <ErrorMessage component="span" name="district" />
            <Field name="city" placeholder="Cidade" autoComplete="off" />
            <ErrorMessage component="span" name="city" />
            <Field
              autoComplete="off"
              name="state"
              render={() => (
                <MaskedInput
                  value={formik.values.state}
                  guide={false}
                  mask={stateMask}
                  id="state"
                  placeholder="Estado"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              )}
            />
            <ErrorMessage component="span" name="state" />
            <Field
              autoComplete="off"
              name="postalCode"
              render={() => (
                <MaskedInput
                  value={formik.values.postalCode}
                  mask={cepMask}
                  id="postalCode"
                  placeholder="Cep"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              )}
            />
            <ErrorMessage component="span" name="postalCode" />
            <Field
              autoComplete="off"
              name="tel"
              render={() => (
                <MaskedInput
                  value={formik.values.tel}
                  mask={telNumberMask}
                  id="tel"
                  placeholder="Telefone"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              )}
            />
            <ErrorMessage component="span" name="tel" />

            <button type="submit" disabled={loading}>
              Salvar igreja
            </button>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

EditChurch.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
