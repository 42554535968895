import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Icon } from 'antd';
import * as Yup from 'yup';

import { signInRequest } from '~/store/modules/auth/actions';

import logo from '~/assets/logo.svg';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});
export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  return (
    <>
      <Link to="/">
        <img src={logo} alt="logoIPR" />
      </Link>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={schema}
        onSubmit={({ email, password }) => {
          dispatch(signInRequest(email, password));
        }}
      >
        <Form>
          <Field name="email" type="email" placeholder="Seu e-mail" />
          <ErrorMessage component="span" name="email" />
          <Field
            name="password"
            type="password"
            placeholder="Sua senha secreta"
          />
          <ErrorMessage component="span" name="password" />
          <button type="submit">
            {loading ? <Icon type="loading" /> : 'Acessar'}
          </button>
          {/* <Link to="/register-new-user">Criar conta</Link> */}
          <Link to="/reset-password">Esqueceu senha ?</Link>
        </Form>
      </Formik>
    </>
  );
}
