import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Icon, Tooltip, Pagination, Popconfirm } from 'antd';
import { FaNetworkWired, FaTrash, FaPen } from 'react-icons/fa';

import api from '~/services/api';
import {
  Container,
  ItemDepartment,
  ActionContainer,
  LoadingWrapper,
  ContentWrapper,
} from './styles';
import TitlePage from '~/components/TitlePage';

export default function Department() {
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    async function loadDepartments() {
      const response = await api.get('departments', {
        params: {
          page: 1,
        },
      });
      setTotal(response.data.totalDocs);
      setDepartments(response.data.docs);
      setLoading(false);
    }

    loadDepartments();
  }, []);

  async function handlePagination(page) {
    setLoading(true);
    const response = await api.get('departments', {
      params: {
        page,
      },
    });
    setTotal(response.data.totalDocs);
    setDepartments(response.data.docs);
    setLoading(false);
  }

  async function handleDelete(id) {
    await api.delete(`departments/${id}`);
    setDepartments(departments.filter(department => department._id !== id));
    toast.success('Ministério excluido com sucesso');
  }

  return (
    <Container>
      <TitlePage>Ministérios</TitlePage>
      <Link to="/department-add">Novo ministério</Link>
      <ContentWrapper>
        {loading ? (
          <LoadingWrapper>
            <Icon type="loading" style={{ color: '#fff', fontSize: '50px' }} />
          </LoadingWrapper>
        ) : (
          <ul>
            {departments.map(department => (
              <ItemDepartment key={department._id}>
                <strong>
                  <FaNetworkWired size={16} />
                  {department.name}
                </strong>
                <ActionContainer>
                  <Tooltip title="Editar">
                    <Link to={`/department-edit/${department._id}`}>
                      <FaPen size={16} />
                    </Link>
                  </Tooltip>
                  <Popconfirm
                    onConfirm={() => handleDelete(department._id)}
                    title="Deseja excluir ministério?"
                    cancelText="Cancelar"
                    okText="Confirmar"
                    icon={
                      <Icon type="question-circle-o" style={{ color: 'red' }} />
                    }
                  >
                    <FaTrash size={16} />
                  </Popconfirm>
                </ActionContainer>
              </ItemDepartment>
            ))}
          </ul>
        )}
        <Pagination
          onChange={page => handlePagination(page)}
          defaultCurrent={1}
          total={total}
          pageSize={5}
        />
      </ContentWrapper>
    </Container>
  );
}
