import api from '~/services/api';

export async function handleMemberRecord(id) {
  const response = await api.get(`/pdf/${id}/member-record`, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
    },
  });

  const binaryData = [];
  binaryData.push(response.data);
  window.open(
    window.URL.createObjectURL(
      new Blob(binaryData, { type: 'application/pdf' })
    )
  );
}

export async function handleMemberCard(id) {
  const response = await api.get(`/pdf/${id}/member-card`, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
    },
  });

  const binaryData = [];
  binaryData.push(response.data);
  window.open(
    window.URL.createObjectURL(
      new Blob(binaryData, { type: 'application/pdf' })
    )
  );
}

export async function handleMemberCertificate(id) {
  const response = await api.get(`/pdf/${id}/member-certificate`, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
    },
  });

  const binaryData = [];
  binaryData.push(response.data);
  window.open(
    window.URL.createObjectURL(
      new Blob(binaryData, { type: 'application/pdf' })
    )
  );
}

export async function handleBabyCertificate(values) {
  const response = await api.post(
    `/pdf/baby-certificate`,
    { values },
    {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    }
  );

  const binaryData = [];
  binaryData.push(response.data);
  window.open(
    window.URL.createObjectURL(
      new Blob(binaryData, { type: 'application/pdf' })
    )
  );
}

export async function handleMerriageCertificate(values) {
  const response = await api.post(
    `/pdf/merriage-certificate`,
    { values },
    {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    }
  );

  const binaryData = [];
  binaryData.push(response.data);
  window.open(
    window.URL.createObjectURL(
      new Blob(binaryData, { type: 'application/pdf' })
    )
  );
}

export async function handleMembersPoll() {
  const response = await api.get(`/pdf/members-poll`, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
    },
  });

  const binaryData = [];
  binaryData.push(response.data);
  window.open(
    window.URL.createObjectURL(
      new Blob(binaryData, { type: 'application/pdf' })
    )
  );
}

export async function handleTransferLetter(values) {
  const response = await api.post(
    `/pdf/member-transfer`,
    { values },
    {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    }
  );

  const binaryData = [];
  binaryData.push(response.data);
  window.open(
    window.URL.createObjectURL(
      new Blob(binaryData, { type: 'application/pdf' })
    )
  );
}

export async function handlePresentationLetter(values) {
  const response = await api.post(
    `/pdf/member-presentation`,
    { values },
    {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    }
  );

  const binaryData = [];
  binaryData.push(response.data);
  window.open(
    window.URL.createObjectURL(
      new Blob(binaryData, { type: 'application/pdf' })
    )
  );
}

export async function handleMemberCardsBatch(members) {
  const binaryData = [];

  const response = await api.post(
    `/pdf/members-card-batch`,
    { members },
    {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    }
  );

  const newWindow = window.open();
  binaryData.push(response.data);
  newWindow.location = URL.createObjectURL(
    new Blob(binaryData, { type: 'application/pdf' })
  );
}
