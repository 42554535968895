import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '~/pages/SignIn';
import SignUp from '~/pages/SignUp';
import ResetPassword from '~/pages/ResetPassword';
import Dashboard from '~/pages/Dashboard';
import Profile from '~/pages/Profile';
import Church from '~/pages/Church';
import NewChurch from '~/pages/Church/New';
import EditChurch from '~/pages/Church/Edit';
import Department from '~/pages/Department';
import NewDepartment from '~/pages/Department/New';
import EditDepartment from '~/pages/Department/Edit';
import Role from '~/pages/Role';
import NewRole from '~/pages/Role/New';
import EditRole from '~/pages/Role/Edit';
import Member from '~/pages/Member';
import NewMember from '~/pages/Member/New';
import EditMember from '~/pages/Member/Edit';
import ShowMember from '~/pages/Member/Show';
import Document from '~/pages/Document';
import DocumentKids from '~/pages/Document/KidsCertificate';
import DocumentMerriage from '~/pages/Document/MerriageCertificate';
import DocumentTransfer from '~/pages/Document/TransferLetter';
import DocumentPresentation from '~/pages/Document/PresentationLetter';
import MembersCard from '~/pages/Document/MemberCardsBatch';
import ItemDashBoard from '~/pages/Dashboard/ItemDashboard';
import Qrcode from '~/pages/Qrcode';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/register-new-user" component={SignUp} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/qrcode/:id" component={Qrcode} />

      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />

      <Route path="/member" component={Member} isPrivate />
      <Route path="/member-add" component={NewMember} isPrivate />
      <Route path="/member-edit/:id" component={EditMember} isPrivate />
      <Route path="/member-show/:id" component={ShowMember} isPrivate />

      <Route path="/church" component={Church} isPrivate />
      <Route path="/church-add" component={NewChurch} isPrivate />
      <Route path="/church-edit/:id" component={EditChurch} isPrivate />

      <Route path="/role" component={Role} isPrivate />
      <Route path="/role-add" component={NewRole} isPrivate />
      <Route path="/role-edit/:id" component={EditRole} isPrivate />

      <Route path="/department" component={Department} isPrivate />
      <Route path="/department-add" component={NewDepartment} isPrivate />
      <Route path="/department-edit/:id" component={EditDepartment} isPrivate />

      <Route path="/document" component={Document} isPrivate />
      <Route path="/document-kids" component={DocumentKids} isPrivate />
      <Route path="/document-merriage" component={DocumentMerriage} isPrivate />
      <Route path="/document-transfer" component={DocumentTransfer} isPrivate />
      <Route path="/members-card" component={MembersCard} isPrivate />
      <Route
        path="/document-presentation"
        component={DocumentPresentation}
        isPrivate
      />

      <Route path="/dashboard-item/:type" component={ItemDashBoard} isPrivate />
    </Switch>
  );
}
