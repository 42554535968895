import React from 'react';
import styled from 'styled-components';
import ReactSlider from 'react-slider';

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 15px;
`;

const StyledThumb = styled.div`
  height: 15px;
  line-height: 15px;
  width: 15px;
  text-align: center;
  background-color: #449aff;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
`;

const Thumb = props => <StyledThumb {...props} />;

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: #fff;
  border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

export const SliderFinal = props => (
  <StyledSlider {...props} renderTrack={Track} renderThumb={Thumb} />
);
