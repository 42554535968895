import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip, Icon, Pagination, Popconfirm } from 'antd';
import { FaChurch, FaTrash, FaPen, FaBible } from 'react-icons/fa';

import {
  Container,
  ItemChurch,
  ActionContainer,
  LoadingWrapper,
} from './styles';
import TitlePage from '~/components/TitlePage';

import api from '~/services/api';

export default function Church() {
  const [loading, setLoading] = useState(true);
  const [churchs, setChurchs] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    async function loadingChurchs() {
      const response = await api.get('churchs', {
        params: {
          page: 1,
        },
      });
      setTotal(response.data.totalDocs);
      setChurchs(response.data.docs);
      setLoading(false);
    }

    loadingChurchs();
  }, []);

  async function handlePagination(page) {
    setLoading(true);
    const response = await api.get('churchs', {
      params: {
        page,
      },
    });
    setTotal(response.data.totalDocs);
    setChurchs(response.data.docs);
    setLoading(false);
  }

  async function handleDelete(id) {
    await api.delete(`churchs/${id}`);
    setChurchs(churchs.filter(church => church._id !== id));
    toast.success('Igreja excluida com sucesso');
  }

  return (
    <Container>
      <TitlePage>Igrejas</TitlePage>
      <Link to="/church-add">Nova igreja</Link>
      {loading ? (
        <LoadingWrapper>
          <Icon type="loading" style={{ color: '#fff', fontSize: '50px' }} />
        </LoadingWrapper>
      ) : (
        <ul>
          {churchs.map(church => (
            <ItemChurch key={church._id}>
              <strong>
                <FaChurch size={16} />
                {church.name}
              </strong>
              <strong>
                <FaBible size={16} />
                {church.member_id ? church.member_id.name : 'Não há pastor'}
              </strong>
              <span>{church.adress}</span>
              <span>
                {church.city}-{church.state}
              </span>
              <span>{church.tel}</span>
              <ActionContainer>
                <Tooltip title="Editar">
                  <Link to={`/church-edit/${church._id}`}>
                    <FaPen size={16} />
                  </Link>
                </Tooltip>
                <Popconfirm
                  onConfirm={() => handleDelete(church._id)}
                  title="Deseja excluir igreja?"
                  cancelText="Cancelar"
                  okText="Confirmar"
                  icon={
                    <Icon type="question-circle-o" style={{ color: 'red' }} />
                  }
                >
                  <FaTrash size={16} />
                </Popconfirm>
              </ActionContainer>
            </ItemChurch>
          ))}
        </ul>
      )}
      <Pagination
        onChange={page => handlePagination(page)}
        defaultCurrent={1}
        total={total}
        pageSize={3}
      />
    </Container>
  );
}
