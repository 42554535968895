import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  background: #e6ecf0;
  border-radius: 10px;
  height: 40vh;
  min-width: 50vw;

  img {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    border: 3px solid #fff;
    margin-top: -100px;
  }

  @media (max-width: 1024px) {
    img {
      margin-top: -90px;
      height: 170px;
      width: 170px;
    }
  }

  @media (max-width: 768px) {
    img {
      margin-top: -75px;
      height: 150px;
      width: 150px;
    }
    width: 500px;
  }

  @media (max-width: 425px) {
    img {
      margin-top: -60px;
      height: 120px;
      width: 120px;
    }

    width: 280px;
  }
`;

export const WrapperINfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  ul {
    text-align: center;
    list-style: none;

    li {
      font-size: 18px;
      color: #657786;

      display: flex;
      align-items: center;
      margin-top: 5px;

      svg {
        font-size: 18px;
        margin-right: 10px;
      }
      strong {
        font-size: 20px;
      }

      @media (max-width: 768px) {
        font-size: 14px;
        svg {
          font-size: 14px;
        }
        strong {
          font-size: 16px;
        }
      }

      @media (max-width: 425px) {
        font-size: 14px;
        svg {
          font-size: 14px;
        }
        strong {
          font-size: 12px;
        }
      }
    }
  }
`;

export const WrapperSkeletonInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  ul {
    text-align: center;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
  }
`;

export const SkeletonLine = styled.div`
  width: ${props => (props.size ? props.size : `100px`)};
  height: 44px;
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }

  @media (max-width: 768px) {
    width: 300px;
  }

  @media (max-width: 425px) {
    width: 200px;
  }
`;

export const SkeletonAvatar = styled.div`
  height: 200px;
  width: 200px;
  border-radius: 50%;
  border: 3px solid #fff;
  margin-top: -220px;
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }

  @media (max-width: 1024px) {
    margin-top: -190px;
    height: 170px;
    width: 170px;
  }

  @media (max-width: 768px) {
    margin-top: -170px;
    height: 150px;
    width: 150px;
  }

  @media (max-width: 425px) {
    margin-top: -140px;
    height: 120px;
    width: 120px;
  }
`;
