import React from 'react';
import PropTypes from 'prop-types';
import { Container, ItemAlphabet } from './styles';

const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export default function Alphabet({ getItem }) {
  return (
    <Container>
      {alphabet.map(item => (
        <ItemAlphabet key={item} onClick={e => getItem(e.target.innerHTML)}>
          {item}
        </ItemAlphabet>
      ))}
    </Container>
  );
}

Alphabet.propTypes = {
  getItem: PropTypes.func.isRequired,
};
