import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { FaChurch, FaRegIdCard, FaCircle } from 'react-icons/fa';
import {
  Container,
  Wrapper,
  WrapperINfo,
  WrapperSkeletonInfo,
  SkeletonLine,
  SkeletonAvatar,
} from './styles';

import api from '~/services/api';
import history from '~/services/history';

export default function Qrcode({ match }) {
  const { id } = match.params;
  const [loading, setLoading] = useState(true);
  const [member, setMember] = useState({});
  const [url, setMemberFileUrl] = useState(null);

  useEffect(() => {
    async function loadingMember() {
      try {
        const response = await api.get(`members/${id}`);
        if (response.data.file_id) {
          setMemberFileUrl(response.data.file_id.url);
        }
        setMember(response.data);
        setLoading(false);
      } catch (err) {
        toast.error(err.response.data.error);
        history.push('/');
      }
    }
    loadingMember();
  }, [id]);

  return (
    <Container>
      <Wrapper>
        <img
          src={url || 'https://api.adorable.io/avatars/50/abott@adorable.png'}
          alt="avatarfake"
        />
        {loading ? (
          <WrapperSkeletonInfo>
            <SkeletonAvatar />
            <ul>
              <li>
                <SkeletonLine size="100px" />
              </li>
              <li>
                <SkeletonLine size="400px" />
              </li>
              <li>
                <SkeletonLine size="300px" />
              </li>
              <li>
                <SkeletonLine size="200px" />
              </li>
            </ul>
          </WrapperSkeletonInfo>
        ) : (
          <WrapperINfo>
            <ul>
              <li>
                <FaRegIdCard />
                {member.identity}
              </li>
              <li>
                <strong>{member.name}</strong>
              </li>
              <li>
                <FaChurch />
                {member.church ? member.church.name : ''}
              </li>
              <li>
                {member.status === 'ATIVO' ? (
                  <FaCircle color="green" />
                ) : (
                  <FaCircle color="red" />
                )}
                <strong>{member.status}</strong>
              </li>
            </ul>
          </WrapperINfo>
        )}
      </Wrapper>
    </Container>
  );
}

Qrcode.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
