import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  border-radius: 5px;
  margin: 10px 10px;

  background: #7698c0;
  display: flex;
  flex-direction: column;

  ul {
    margin: 10px;
  }
`;

export const ItemMember = styled.li`
  display: grid;
  grid-gap: 10px;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(210px, auto));

  margin-bottom: 5px;
  background: #fff;
  padding: 3px 10px;
  border-radius: 4px;
`;

export const UserContact = styled.div`
  width: 200px;
  display: flex;
  flex-grow: 1;
  flex-basis: auto;

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  strong {
    color: #333;
    font-size: 10px;
    font-weight: bold;

    svg {
      display: inline-flex;
      align-self: center;
      height: 1em;
      width: 1em;
      top: 0.125em;
      position: relative;
      margin-right: 5px;
    }
  }
`;

export const UserAdress = styled.div`
  width: 200px;
  display: flex;
  flex-grow: 1;
  flex-basis: auto;
  flex-direction: column;
  justify-content: center;

  strong {
    color: #333;
    font-size: 10px;
    font-weight: bold;

    svg {
      display: inline-flex;
      align-self: center;
      height: 1em;
      width: 1em;
      top: 0.125em;
      position: relative;
      margin-right: 5px;
    }
  }
`;

export const UserInfo = styled.div`
  width: 200px;
  display: flex;
  flex-grow: 1;
  flex-basis: auto;
  flex-direction: column;
  justify-content: stretch;

  strong {
    color: black;
    font-size: 10px;
    font-weight: bold;

    &:nth-child(2) {
      font-size: 14px;
    }

    svg {
      display: inline-flex;
      align-self: center;
      height: 1em;
      width: 1em;
      top: 0.125em;
      position: relative;
      margin-right: 5px;
    }
  }
`;

export const ActionContainer = styled.div`
  svg,
  a {
    color: ${lighten(0.1, '#333')};
    cursor: pointer;
    margin: 5px 10px;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const LoadingWrapper = styled.div`
  margin: 100px 0;
  text-align: center;
`;

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 425px) {
    flex-direction: column;
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 10px 0 10px;
    padding: 10px;
    background: #e67311;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 5px;
    font-size: 16px;
    height: 44px;
    transition: background 0.2s;

    > svg {
      font-size: 14px;
      margin-right: 5px;
    }

    &:hover {
      color: #fff;
      background: ${lighten(0.03, '#E67311')};
    }
  }

  > div {
    display: flex;
    flex-direction: row;

    @media (max-width: 425px) {
      justify-content: center;
    }

    button {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 10px 0 10px;
      padding: 10px;
      background: #e67311;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 5px;
      font-size: 16px;
      transition: background 0.2s;
      &:hover {
        background: ${lighten(0.03, '#E67311')};
      }
    }
  }
`;
