import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';

import DatePicker from '~/components/DatePicker';
import { Container, ListNames, BtnStyled } from './styles';
import TitlePage from '~/components/TitlePage';
import { handlePresentationLetter } from '~/util/pdfapi';

const schema = Yup.object().shape({
  name: Yup.string(),
  initialDate: Yup.date()
    .typeError('Data de apresentação é obrigatório')
    .required('Data de apresentação é obrigatório')
    .nullable(),
});

export default function FormPresentation() {
  const [namesPresentation, setNamesPresentation] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [errorNames, setErrorNames] = useState('');

  function handleNamesPresentation(name, formik) {
    if (name) {
      setErrorNames('');
      setNamesPresentation([...namesPresentation, name]);
      formik.setFieldValue('name', '');
    } else {
      setErrorNames('Nome não pode ser vazio');
    }
  }

  function handleDeleteName(name) {
    setNamesPresentation(namesPresentation.filter(nm => nm !== name));
  }

  return (
    <Container>
      <TitlePage>Carta de Apresentação</TitlePage>
      <Formik
        initialValues={{
          name: '',
          initialDate: null,
        }}
        validationSchema={schema}
        onSubmit={async values => {
          if (namesPresentation.length > 0) {
            setLoadingButton(true);
            await handlePresentationLetter({ ...values, namesPresentation });
            setErrorNames('');
            setLoadingButton(false);
          } else {
            setErrorNames('Adicione um nome para gerar o documento');
          }
        }}
      >
        {formik => (
          <Form>
            <Field
              name="initialDate"
              render={() => (
                <DatePicker
                  name="initialDate"
                  placeholder="Data apresentação"
                  value={formik.values.initialDate}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                />
              )}
            />
            <ErrorMessage component="span" name="initialDate" />

            <div>
              <Field name="name" placeholder="Nome" />
              <button
                type="button"
                onClick={() =>
                  handleNamesPresentation(formik.values.name, formik)
                }
              >
                <FaPlusCircle />
              </button>
              <ErrorMessage component="span" name="name" />
            </div>
            {namesPresentation.map(nm => (
              <ListNames key={nm}>
                <strong>{nm}</strong>
                <button type="button" onClick={() => handleDeleteName(nm)}>
                  <FaTrashAlt />
                </button>
              </ListNames>
            ))}
            <span>{errorNames}</span>

            <BtnStyled type="submit" loadingButton={loadingButton ? 1 : 0}>
              Gerar documento
            </BtnStyled>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
