import React, { useState } from 'react';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import DatePicker from '~/components/DatePicker';
import { stateMask } from '~/util/masks';
import { Container, BtnStyled } from './styles';
import TitlePage from '~/components/TitlePage';
import { handleBabyCertificate } from '~/util/pdfapi';

const schema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigátorio'),
  city: Yup.string().required('Cidade é obrigatória'),
  state: Yup.string()
    .min(2, 'Estado possui dois caracteres ex: SP')
    .required('Estádo é obrigatório'),
  birthDate: Yup.date()
    .typeError('Data de nascimento é obrigatório')
    .max(new Date(), 'Não pode selecionar datas futuras')
    .required('Data de nascimento é obrigatório')
    .nullable(),
  date: Yup.date()
    .typeError('Data de apresentação é obrigatório')
    .required('Data de apresentação é obrigatório')
    .nullable(),
  nameDad: Yup.string().required('Nome do pai é obrigatório'),
  nameMom: Yup.string().required('Nome da mãe é obrigatório'),
  gender: Yup.string().required('Selecione o sexo'),
});

export default function FormKids() {
  const [loadingButton, setLoadingButton] = useState(false);

  return (
    <Container>
      <TitlePage>Certificado de Apresentação</TitlePage>
      <Formik
        initialValues={{
          name: '',
          gender: '',
          date: null,
          birthDate: null,
          city: '',
          state: '',
          nameDad: '',
          nameMom: '',
        }}
        validationSchema={schema}
        onSubmit={async values => {
          setLoadingButton(true);
          await handleBabyCertificate(values);
          setLoadingButton(false);
        }}
      >
        {formik => (
          <Form>
            <Field name="name" placeholder="Nome da criança" />
            <ErrorMessage component="span" name="name" />

            <Field
              name="gender"
              render={() => (
                <select
                  name="gender"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="" defaultValue label="Selecionar o sexo" />
                  <option value="boy" label="Menino" />
                  <option value="girl" label="Menina" />
                </select>
              )}
            />
            <ErrorMessage component="span" name="gender" />

            <Field
              name="date"
              render={() => (
                <DatePicker
                  name="date"
                  placeholder="Data apresentação"
                  value={formik.values.date}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                />
              )}
            />
            <ErrorMessage component="span" name="date" />

            <Field
              name="birthDate"
              render={() => (
                <DatePicker
                  placeholder="Data nascimento"
                  name="birthDate"
                  value={formik.values.birthDate}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                />
              )}
            />
            <ErrorMessage component="span" name="birthDate" />

            <Field name="nameDad" placeholder="Nome do pai" />
            <ErrorMessage component="span" name="nameDad" />

            <Field name="nameMom" placeholder="Nome da mãe" />
            <ErrorMessage component="span" name="nameMom" />

            <Field name="city" placeholder="Cidade" />
            <ErrorMessage component="span" name="city" />
            <Field
              name="state"
              render={() => (
                <MaskedInput
                  guide={false}
                  mask={stateMask}
                  id="state"
                  placeholder="Estado"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              )}
            />
            <ErrorMessage component="span" name="state" />

            <BtnStyled type="submit" loadingButton={loadingButton ? 1 : 0}>
              Gerar documento
            </BtnStyled>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
