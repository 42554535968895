import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';

import { Container } from './styles';
import TitlePage from '~/components/TitlePage';

import api from '~/services/api';
import history from '~/services/history';

const schema = Yup.object().shape({
  name: Yup.string().required('Título do ministério é obrigatório'),
});

export default function EditDepartment({ match }) {
  const { id } = match.params;

  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState([]);

  useEffect(() => {
    setLoading(true);
    async function loadDepartment() {
      try {
        const response = await api.get(`departments/${id}`);
        setDepartment(response.data);
      } catch (error) {
        history.push(`/department`);
      }
    }

    loadDepartment();
    setLoading(false);
  }, [id, match.params.id]);

  async function handleSubmit(data) {
    try {
      setLoading(true);
      await api.put(`departments/${id}`, data);
      toast.success('Ministério alterado com sucesso');
      history.push(`/department`);
    } catch (err) {
      const errData = err.response.data;
      toast.error(
        errData && errData.error
          ? `Error ao editar ministério: ${errData.error}`
          : 'Error updating department, try again'
      );
      setLoading(false);
    }
  }

  return (
    <Container>
      <TitlePage>Editar Ministério</TitlePage>
      <Formik
        enableReinitialize
        initialValues={{
          name: department.name || '',
        }}
        validationSchema={schema}
        onSubmit={async values => handleSubmit(values)}
      >
        <Form>
          <Field
            name="name"
            placeholder="Título do ministério"
            autoComplete="off"
          />
          <ErrorMessage component="span" name="name" />

          <button type="submit" disabled={loading}>
            Salvar ministério
          </button>
        </Form>
      </Formik>
    </Container>
  );
}

EditDepartment.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
