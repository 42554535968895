import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  background: #449aff;
  padding: 0 10px;
`;

export const Content = styled.div`
  height: 64px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  nav {
    display: flex;
    align-items: center;

    button {
      display: flex;
      align-items: center;
      border: none;
      background: transparent;
      color: #fff;
      font-weight: bold;
      font-size: 15px;

      svg {
        font-size: 18px;
        margin-right: 2px;
        transition: transform 0.2s;
      }

      &:hover svg {
        transform: translateX(-2px);
      }

      @media (max-width: 425px) {
        font-size: 0;

        svg {
          font-size: 22px;
        }
      }
    }

    > a {
      border-right: 1px solid #eee;
      margin-right: 23px;
      padding-right: 23px;

      img {
        height: 55px;
        transition: transform 0.2s;
      }

      &:hover img {
        transform: scale(1.05);
      }
    }
  }
`;
export const Profile = styled.div`
  display: flex;
  margin-right: 8px;
  padding-right: 8px;

  div {
    text-align: right;
    margin-right: 10px;

    strong {
      display: block;
      color: #fff;
    }

    a {
      font-weight: bold;
      color: #7159c1;
      display: block;
      margin-top: 2px;
      font-size: 12px;
      &:hover {
        color: ${darken(0.1, '#7159c1')};
      }
    }
  }

  img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: #eee;
  }
`;
