import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  border-radius: 5px;
  margin: 10px 20px;
  /* height: 100vh; */
  /* max-height: 100vh; */
  background: #7698c0;
  display: flex;
  flex-direction: column;

  > a {
    max-width: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 10px 0 10px;
    padding: 10px;
    background: #e67311;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 5px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      color: #fff;
      background: ${lighten(0.03, '#E67311')};
    }
  }

  ul {
    margin: 10px;
  }
`;

export const ItemChurch = styled.li`
  padding: 20px;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 10px;

  strong {
    display: block;
    color: black;
    font-size: 18px;
    font-weight: bold;

    svg {
      margin-right: 8px;
    }

    &:nth-child(2) {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }

  span {
    display: block;
    margin-top: 3px;
    color: #333;
    font-size: 15px;
  }
`;

export const ActionContainer = styled.div`
  margin: 10px 0px 0px 0;
  svg,
  a {
    color: ${lighten(0.1, '#333')};
    cursor: pointer;
    margin-right: 10px;
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const LoadingWrapper = styled.div`
  margin: 100px 0;
  text-align: center;
`;
