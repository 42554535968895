import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import logo from '~/assets/logo.svg';

import api from '~/services/api';
import history from '~/services/history';

const schema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Mínimo 3 caracteres')
    .required('Nome é obrigatório'),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  password: Yup.string()
    .min(6, 'Mínimo 6 characteres')
    .required('A senha é obrigatória'),
});
export default function SignUp() {
  async function handleSubmit({ name, email, password }) {
    try {
      await api.post(`users`, {
        name,
        email,
        password,
        inside: true,
      });
      toast.success('Usuário cadastrado com sucesso');
      history.push('/');
    } catch (err) {
      toast.error(err.response.data.error.message);
      history.push('/');
    }
  }

  return (
    <>
      <Link to="/">
        <img src={logo} alt="logoIPR" />
      </Link>
      <Formik
        initialValues={{
          name: '',
          email: '',
          password: '',
        }}
        validationSchema={schema}
        onSubmit={values => handleSubmit(values)}
      >
        <Form>
          <Field name="name" placeholder="Nome completo" />
          <ErrorMessage component="span" name="name" />
          <Field name="email" type="email" placeholder="Seu e-mail" />
          <ErrorMessage component="span" name="email" />
          <Field
            name="password"
            type="password"
            placeholder="Sua senha secreta"
          />
          <ErrorMessage component="span" name="password" />
          <button type="submit">Criar conta</button>
          <Link to="/">Já tenho conta</Link>
        </Form>
      </Formik>
    </>
  );
}
