import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { Container, BtnStyled } from './styles';
import TitlePage from '~/components/TitlePage';
import { handleTransferLetter } from '~/util/pdfapi';

const schema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
});

export default function FormTransfer() {
  const [loadingButton, setLoadingButton] = useState(false);

  return (
    <Container>
      <TitlePage>Carta de Transferência</TitlePage>
      <Formik
        initialValues={{
          name: '',
        }}
        validationSchema={schema}
        onSubmit={async values => {
          setLoadingButton(true);
          await handleTransferLetter(values);
          setLoadingButton(false);
        }}
      >
        <Form>
          <Field name="name" placeholder="Nome" />
          <ErrorMessage component="span" name="name" />

          <BtnStyled type="submit" loadingButton={loadingButton ? 1 : 0}>
            Gerar documento
          </BtnStyled>
        </Form>
      </Formik>
    </Container>
  );
}
