import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon, Pagination, Tooltip } from 'antd';
import {
  FaWhatsapp,
  FaPhone,
  FaChurch,
  FaCalendarAlt,
  FaHome,
  FaCity,
  FaBan,
  FaRegEye,
} from 'react-icons/fa';
import { format } from 'date-fns';

import TitlePage from '~/components/TitlePage';
import {
  Container,
  LoadingWrapper,
  ItemMember,
  UserInfo,
  UserAdress,
  UserContact,
  EmptyList,
  ActionContainer,
} from './styles';
import api from '~/services/api';

const title = [
  { type: 'ATIVO', content: 'Lista de membros ativos' },
  { type: 'INATIVO', content: 'Lista de membros inativos' },
  { type: 'AFASTADO', content: 'Lista de membros afastados' },
  { type: 'OBITO', content: 'Lista de membros falecidos' },
  { type: 'ANIVERSARIO', content: 'Lista de aniversariantes mês' },
];

export default function Active({ match }) {
  const { type } = match.params;
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    async function loadingMembers() {
      const response = await api.get('/item-dashboard', {
        params: {
          type,
        },
      });
      setTotal(response.data.totalDocs);
      setMembers(response.data.docs);
      setLoading(false);
    }
    loadingMembers();
  }, [type]);

  async function handlePagination(page) {
    setLoading(true);
    const response = await api.get('/item-dashboard', {
      params: {
        type,
        page,
      },
    });
    setTotal(response.data.totalDocs);
    setMembers(response.data.docs);
    setLoading(false);
  }

  return (
    <Container>
      <TitlePage>
        {title.map(item => (item.type === type ? item.content : ''))}
      </TitlePage>
      {loading ? (
        <LoadingWrapper>
          <Icon type="loading" style={{ color: '#fff', fontSize: '50px' }} />
        </LoadingWrapper>
      ) : (
        <ul>
          {members.length === 0 ? (
            <EmptyList>
              <strong>Lista Vazia</strong>
              <FaBan />
            </EmptyList>
          ) : (
            members.map(member => (
              <ItemMember key={member._id}>
                <UserInfo>
                  <strong>
                    {member.identity} -{' '}
                    {member.church ? (
                      <strong>
                        <FaChurch />
                        {member.church.name}
                      </strong>
                    ) : (
                      <strong>
                        <FaChurch />
                        Sem igreja
                      </strong>
                    )}
                  </strong>

                  <strong>{member.name}</strong>
                  <strong>
                    <FaCalendarAlt />
                    {format(new Date(member.birth), 'dd/MM/yyyy')}
                  </strong>
                </UserInfo>
                <UserContact>
                  {member.phone ? (
                    <strong>
                      <FaWhatsapp />
                      {member.phone}
                    </strong>
                  ) : (
                    ''
                  )}
                  <strong>
                    <FaPhone />
                    {member.tel}
                  </strong>
                </UserContact>
                <UserAdress>
                  <strong>
                    <FaHome />
                    {member.adress}
                  </strong>
                  <strong>
                    <FaCity />
                    {member.city} - {member.state}
                  </strong>
                </UserAdress>
                <ActionContainer>
                  <Tooltip title="Visualizar">
                    <Link to={`/member-show/${member._id}`}>
                      <FaRegEye size={16} />
                    </Link>
                  </Tooltip>
                </ActionContainer>
              </ItemMember>
            ))
          )}
        </ul>
      )}
      <Pagination
        onChange={page => handlePagination(page)}
        defaultCurrent={1}
        total={total}
        pageSize={8}
      />
    </Container>
  );
}

Active.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
